import React from 'react';

import { TextS } from '../styles/Texts';
import artistes from './data';
import { ArtistsWrap, ArtistItem } from './artists.style';

function Artists() {
  return (
    <ArtistsWrap>
      {artistes.map((el, i) => (
        <ArtistItem key={i}>
          <img src={el.img} alt="artist" />
          <TextS>{el.name}</TextS>
        </ArtistItem>
      ))}
    </ArtistsWrap>
  );
}

export default Artists;
