import React, { Fragment } from 'react';

import Container, { Box } from '../Components/styles/Container';
import Banner from '../Components/Banner';
import PaymentSolutions from '../Components/PaymentSolutions';
import BannerOffer from '../Components/BannerOffer';
import ServicesShowcase from '../Components/servicesShowcase/ServicesShowcase';
import Faq from '../Components/Faq';
import Button from '../Components/styles/Button';
import Link from '../Components/styles/Link';
import { Title1, Title2 } from '../Components/styles/Titles';
import imgCover from '../img/music.jpg';
import { useTranslation } from 'react-i18next';

export default function Index() {
  const isLogged = localStorage.getItem('access_token');
  const isAdmin = isLogged && localStorage.getItem('idRole') !== '1';
  const { t } = useTranslation();
  return (
    <Fragment>
      <Banner img={imgCover} alt="Beyonce YouTrace Pro" imgHeight="400px" cta>
        <Title1 dangerouslySetInnerHTML={{ __html: t('home.title1') }} />
        <Box mb={4}>
          <Title2 light>
            {t('home.title2.part1')} <span>{t('home.title2.part2')}</span>
            <br /> {t('home.title2.part3')} <span>{t('home.title2.part4')}</span>
          </Title2>
        </Box>
        {isLogged ? (
          isAdmin ? (
            <Button href="/dashboard">Dashboard</Button>
          ) : (
            <Button href="/services">{t('services')}</Button>
          )
        ) : (
          <>
            <Button href="/inscription">{t('register')}</Button>
            <Box mt={3}>
              <Link href="/connexion" orange style={{ marginLeft: 'auto' }}>
                {t('login')}
              </Link>
            </Box>
          </>
        )}
      </Banner>
      <Box py={[3, 4]} center>
        <Container>
          <Title2>
            {t('home.title2.part5')}{' '}
            <span>
              {t('home.title2.part6')}
              <br />
            </span>
            {t('home.title2.part7')}
          </Title2>
        </Container>
      </Box>
      <ServicesShowcase />
      <BannerOffer />
      <Faq />
      <PaymentSolutions />
    </Fragment>
  );
}
