import React from 'react';
import uuid from 'uuid';

import { useTranslation } from 'react-i18next';
import { Box } from '../styles/Container';
import { Input, Switcher, SwitchItem } from '../styles/Form';
import { Text, TextL } from '../styles/Texts';
import bgUrl from '../../img/option-distribution.jpg';

export default function Distribution({
  onChange,
  isMobile,
  onChangeCountry,
  onSuggestions,
  ...props
}) {
  function handleCheckboxes(checked, field) {
    const e = {
      target: {
        type: 'checkbox',
        checked
      }
    };

    onChange(e, field);
  }

  const checked = props['optionDistribution'] || false;
  const { t } = useTranslation();
  return (
    <div className={checked ? 'checked' : ''}>
      <SwitchItem
        tShadow
        key={uuid()}
        style={{ backgroundImage: `url(${bgUrl || ''})` }}
        onClick={() => handleCheckboxes(!checked, 'optionDistribution')}
        className={checked && 'checked'}
      >
        <Box className="overlay" />
        <TextL>{t('distribution.title')}</TextL>
        <Switcher>
          <Input
            onChange={() => handleCheckboxes(!checked, 'optionDistribution')}
            type="checkbox"
            id="optionDistribution"
            name="radio"
            checked={checked}
          />
          <label htmlFor="optionDistribution">
            <span></span>
          </label>
        </Switcher>
        <Box fWidth pt={3}>
          <Text dangerouslySetInnerHTML={{ __html: t('distribution.resume') }} />
          {props['optionRealisation'] && (
            <Text orange py={1}>
              {t('distribution.free')}
            </Text>
          )}
        </Box>
      </SwitchItem>
    </div>
  );
}
