import styled, { css } from 'styled-components';
import { flexbox, space } from 'styled-system';

const Container = styled.div`
	margin: 0 auto;
	width: 1087px;
	max-width: 100%;
	box-sizing: border-box;
	position: relative;
	padding: 0 20px;
	${space}
	${props =>
    props.bgWhite &&
    css`
      background: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.secondary};
    `}
	${props =>
    props.fluid &&
    css`
      width: 100%;
      padding: 0 60px;
    `}
	${props =>
    props.small &&
    css`
      max-width: 580px;
    `}

	${props =>
    props.XS &&
    css`
      max-width: 450px;
    `}
	${props =>
    props.flex &&
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    `}
		&,
		&--fluid {
			@media ${props => props.theme.mediaQueries.mediumWidth} {
				width: 100%;
				padding: 0 25px;
			}
		}

		&,
		&--fluid {
			@media ${props => props.theme.mediaQueries.smallWidth} {
				padding: 0 15px;
			}
		}
`;

const Box = styled.div`
	${space}
	${props =>
    props.bgWhite &&
    css`
      background: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.secondary};
    `}
	${props =>
    props.flex &&
    css`
      display: flex;
    `}
	${props =>
    props.inlineBlock &&
    css`
      display: inline-block;
    `}
	${props =>
    props.secondary &&
    css`
      color: ${props => props.theme.colors.secondary};
    `}
	${props =>
    props.center &&
    css`
      text-align: center;
      width: 100%;
    `}

    ${props =>
      props.tRight &&
      css`
        text-align: right;
      `}
	${props =>
    props.hideMedium &&
    css`
      @media ${props => props.theme.mediaQueries.mediumWidth} {
        display: none;
      }
    `}

	${props =>
    props.showMedium &&
    css`
      display: none;
      @media ${props => props.theme.mediaQueries.mediumWidth} {
        display: block;
      }
    `}
	${props =>
    props.fWidth &&
    css`
      width: 100%;
    `}
	${props =>
    props.border &&
    css`
      border: 1px solid;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${flexbox};
  ${space}
`;

export default Container;
export { Box, Flex };
