import React from 'react';
import { Box } from '../styles/Container';
import { List, Select } from '../styles/Form';

class Dropdown extends React.Component {
  constructor() {
    super();
    this.state = {
      showOptionList: false
    };
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ showOptionList: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ showOptionList: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  render() {
    return (
      <Box>
        <Select onClick={this.showDropdownMenu}>
          {this.props.label}
          <span>{this.state.showOptionList ? '▲' : '▼'}</span>
        </Select>
        {this.state.showOptionList ? (
          <List>
            {this.props.options.map((option, i) => (
              <li
                name={option}
                values={this.props.values?.[i]}
                code={i}
                key={i}
                onClick={this.props.handleClick}
              >
                {option}
              </li>
            ))}
          </List>
        ) : null}
      </Box>
    );
  }
}

export default Dropdown;
