import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo.png';
import logoBlack from '../../img/logo-negativ.png';

function Logo({ theme }) {
  return (
    <Link to="/">
      <img src={theme === 'dark' ? logo : logoBlack} alt="logo" />
    </Link>
  );
}

export default Logo;
