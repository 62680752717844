import React from 'react';
import uuid from 'uuid';

import { Box } from '../styles/Container';
import { Input, Switcher, SwitchItem } from '../styles/Form';
import { Text, TextL } from '../styles/Texts';
import bgUrl from '../../img/option-diffusion.jpg';
import PromoDigitale from './PromoDigitale';
import { useTranslation } from 'react-i18next';

export default function Options({ onChange, isMobile, ...props }) {
  function handleCheckboxes(checked, field) {
    const e = {
      target: {
        type: 'checkbox',
        checked
      }
    };
    onChange(e, field);
  }

  const checked = props['optionPromotion'] || false;
  const { t } = useTranslation();
  return (
    <>
      <SwitchItem
        key={uuid()}
        style={{ backgroundImage: `url(${bgUrl || ''})` }}
        onClick={() => handleCheckboxes(!checked, 'optionPromotion')}
        className={checked && 'checked'}
        tShadow
      >
        <Box className="overlay"/>
        <TextL>{t('digitalPromo.title')}</TextL>
        <Switcher>
          <Input
            onChange={() => handleCheckboxes(!checked, 'optionPromotion')}
            type="checkbox"
            id="optionPromotion"
            name="radio"
            checked={checked}
          />
          <label htmlFor="optionPromotion">
            <span></span>
          </label>
        </Switcher>
        <Box fWidth pt={3}>
          <Text>{t('digitalPromo.resume')}</Text>
        </Box>
      </SwitchItem>
      {props['optionPromotion'] && <PromoDigitale onChange={onChange} {...props} />}
    </>
  );
}
