/*eslint eqeqeq: 0*/
import React, { Component } from 'react';

import Informations from './../Components/subscription/Informations';
import Services from './../Components/subscription/Services';
import Calendar from './../Components/subscription/Calendar';
import Recap from './../Components/subscription/Recap';
import api from '../client/api';
import Coordinateur from './Coordinateur';
import Wari from './Wari';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: true,
      informations: false,
      calendar: false,
      recap: false,
      payer: false,
      clipExists: false,
      isArtiste: false,
      conditions: false,
      activediv: 0,
      firstName: '',
      lastName: '',
      emailAddress: '',
      validated: false,
      optionRealisation: false,
      optionPromotion: false,
      optionDiffusion: false,
      optionDistribution: false,
      payment: false,
      artisteName: '',
      titleName: '',
      label: '',
      producer: '',
      producerStreet: '',
      producerCity: '',
      producerCountry: '',
      clipUrl: '',
      auteur_0: '',
      compositeur_0: '',
      countries: [],
      selectedCountry: null,
      diffusionDate: null,
      identity: null,
      errors: null,
      'price-mode': 'coordinateur',
      disabledDates: [],
      schedules: []
    };
    this.handleConditions = this.handleConditions.bind(this);
    this.handleSkipRegistration = this.handleSkipRegistration.bind(this);
    this.handleRegistration = this.handleRegistration.bind(this);
    this.handlePayer = this.handlePayer.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.goToServices = this.goToServices.bind(this);
    this.goToCalendar = this.goToCalendar.bind(this);
    this.goToInformations = this.goToInformations.bind(this);
    this.goToRecap = this.goToRecap.bind(this);
    this.pay = this.pay.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.getPromoPrice = this.getPromoPrice.bind(this);
    this.getPackPrice = this.getPackPrice.bind(this);
    this.getDiffusionPrice = this.getDiffusionPrice.bind(this);
    this.turnOffDiffusion = this.turnOffDiffusion.bind(this);
    this.defaultSelectedCountry = this.defaultSelectedCountry.bind(this);
    this.onSuggestions = this.onSuggestions.bind(this);
    this.handleCCProd = this.handleCCProd.bind(this);
    this.handleProducerCountryChange = this.handleProducerCountryChange.bind(this);
  }

  componentDidMount() {
    api.fetchCountries(countries => {
      this.setState({ countries: countries });
      api.getUser(user => {
        this.defaultSelectedCountry(user.countryCode, countries);
        this.setState({ user: user });
      });
    });
    api.schedule(schedules => this.setState({ schedules: schedules }));
  }

  defaultSelectedCountry(countryCode, countries) {
    let selectedCountry = countries.find(c => c.code.toLowerCase() == countryCode.toLowerCase());
    if (selectedCountry) {
      this.setState({
        selectedCountry: selectedCountry
      });
    } else {
      this.setState({
        selectedCountry: {
          identity: 17,
          country: 'France',
          reference: true,
          currency: 'EUR',
          euroRate: '1',
          code: 'FR',
          productionAvailable: true,
          zone: {
            identity: 5,
            name: 'France & Caraibes & OI'
          },
          countryServices: [
            {
              identity: 62,
              service: {
                identity: 1,
                serviceType: 'PACK_SILVER',
                traceTvPercentage: 40
              },
              amount: 1000
            },
            {
              identity: 63,
              service: {
                identity: 2,
                serviceType: 'PACK_GOLD',
                traceTvPercentage: 40
              },
              amount: 1500
            },
            {
              identity: 64,
              service: {
                identity: 3,
                serviceType: 'PACK_PLATINIUM',
                traceTvPercentage: 40
              },
              amount: 3000
            },
            {
              identity: 65,
              service: {
                identity: 4,
                serviceType: 'UPLOAD',
                traceTvPercentage: 85
              },
              amount: 150
            },
            {
              identity: 66,
              service: {
                identity: 5,
                serviceType: 'PROMO_CLASSIC',
                traceTvPercentage: 85
              },
              amount: 250
            },
            {
              identity: 67,
              service: {
                identity: 6,
                serviceType: 'PROMO_PREMIUM',
                traceTvPercentage: 85
              },
              amount: 500
            }
          ]
        }
      });
    }
  }

  handleCountryChange(country) {
    this.setState({ selectedCountry: country, errors: null });
  }

  handleDate(date) {
    this.setState({ diffusionDate: date });
  }

  handlePayer(e) {
    e.preventDefault();
    this.setState({
      services: false,
      informations: false,
      calendar: false,
      recap: false,
      payer: true,
      activediv: 3
    });
  }

  getPackPrice() {
    let packPrice = 0;
    if (this.state.optionRealisation) {
      if (this.state['option-realisation'] === 'silver') {
        packPrice = this.state['selectedCountry'].countryServices.find(
          cs => cs.service.serviceType === 'PACK_SILVER'
        ).amount;
      } else if (this.state['option-realisation'] === 'gold') {
        packPrice = this.state['selectedCountry'].countryServices.find(
          cs => cs.service.serviceType === 'PACK_GOLD'
        ).amount;
      } else {
        packPrice = this.state['selectedCountry'].countryServices.find(
          cs => cs.service.serviceType === 'PACK_PLATINIUM'
        ).amount;
      }
    }
    return packPrice;
  }

  getPromoPrice() {
    let promoPrice = 0;
    if (this.state['option-diffusion'] === 'youtrace') {
      promoPrice = this.state['selectedCountry'].countryServices.find(
        cs => cs.service.serviceType === 'PROMO_CLASSIC'
      ).amount;
    } else if (this.state['option-diffusion'] === 'youtrace-trace') {
      promoPrice = this.state['selectedCountry'].countryServices.find(
        cs => cs.service.serviceType === 'PROMO_PREMIUM'
      ).amount;
    }
    return promoPrice;
  }

  getDiffusionPrice() {
    let diffusionPrice = 0;
    if (!this.state.optionRealisation && this.state.optionDiffusion) {
      diffusionPrice = this.state.selectedCountry.countryServices.find(
        cs => cs.service.serviceType === 'UPLOAD'
      ).amount;
    }
    return diffusionPrice;
  }

  handleChange(e, field) {
    this.setState({ errors: null });
    switch (e.target.type) {
      case 'checkbox':
        this.setState({ [field]: e.target.checked });
        break;
      case 'radio':
        this.setState({ [field]: e.target.id });
        break;
      default:
        if (field != 'producerCountry') {
          this.setState({ [field]: e.target.value });
        }
    }
    if (field === 'optionRealisation') {
      this.setState({ optionDiffusion: e.target.checked });
      this.setState({ optionDistribution: e.target.checked });
      if (e.target.checked === false) {
        this.defaultSelectedCountry(this.state.user.countryCode, this.state.countries);
      }
    }
    if (field === 'optionPromotion' || field === 'optionDiffusion') {
      this.setState({ optionDistribution: e.target.checked });
    }
  }

  goToAccount() {
    window.location.href = '/connexion';
  }

  turnOffDiffusion() {
    this.setState({ optionDiffusion: !this.state.optionDiffusion });
  }

  handleConditions(e) {
    this.setState({ conditions: e.currentTarget.checked });
  }

  goToInformations() {
    if (
      this.state.optionRealisation === true &&
      this.state.selectedCountry.identity === undefined
    ) {
      this.setState({ errors: this.props.t('errorChooseCountry') });
    } else if (this.state.optionRealisation === true && this.state['option-realisation'] == null) {
      this.setState({ errors: this.props.t('errorChoosePack') });
    } else if (this.state['option-diffusion'] == null && this.state.optionPromotion === true) {
      this.setState({
        errors: this.props.t('errorChoosePromo')
      });
    } else if (
      this.state.optionRealisation === false &&
      this.state.optionPromotion === false &&
      this.state.optionDistribution === false &&
      this.state.optionDiffusion === false
    ) {
      this.setState({
        errors: this.props.t('errorChooseOne')
      });
    } else if (
      this.state.optionRealisation === false &&
      this.state.optionPromotion === false &&
      this.state.optionDistribution === true &&
      this.state.optionDiffusion === false &&
      this.state.user.orderExists
    ) {
      this.setState({
        errors: null,
        services: false,
        calendar: false,
        informations: false,
        recap: true,
        payer: false,
        activediv: 2
      });
    } else if (
      this.state.optionRealisation === false &&
      this.state.optionPromotion === false &&
      this.state.optionDistribution === true &&
      this.state.optionDiffusion === false &&
      !this.state.user.orderExists
    ) {
      this.setState({
        errors: this.props.t('errorDistribution')
      });
    } else {
      this.setState({
        errors: null,
        services: false,
        calendar: false,
        informations: true,
        recap: false,
        payer: false,
        activediv: 2
      });
    }
  }

  goToServices() {
    this.setState({
      services: true,
      informations: false,
      recap: false,
      payer: false,
      activediv: 1,
      errors: null
    });
  }

  goToCalendar() {
    this.setState({
      services: false,
      calendar: true,
      informations: false,
      recap: false,
      payer: false
    });
  }

  goToRecap() {
    if (this.state.optionRealisation === false) {
      if (this.state.diffusionDate) {
        this.setState({
          services: false,
          informations: false,
          calendar: false,
          recap: true,
          payer: false,
          activediv: 1,
          error: null
        });
      } else {
        this.setState({
          error: this.props.t('selectDate')
        });
      }
    } else {
      this.setState({
        services: false,
        informations: false,
        calendar: false,
        recap: true,
        payer: false,
        activediv: 1
      });
    }
  }

  handleSkipRegistration(e) {
    this.setState({ registration: false, clip: true, activediv: 1 });
  }

  handleRegistration(e) {
    e.preventDefault();
    this.setState({ clip: true, registration: false, activediv: 2 });
  }

  handleProducerCountryChange(country) {
    this.setState({
      producerCountry: country[0].label
    });
  }

  onSuggestions(suggestions) {
    if (suggestions.length === 1) {
      this.setState({ producerCountry: suggestions[0].label });
    } else {
      this.setState({ producerCountry: '' });
    }
  }

  async pay(payment) {
    if (
      this.state['accept_monetisation'] === false ||
      (this.state['accept_monetisation'] === undefined && this.state['optionRealisation'])
    ) {
      this.setState({ errors: this.props.t('errorCoprod') });
    } else if (
      this.state['optionDistribution'] &&
      (this.state['accept_distribution'] === false ||
        this.state['accept_distribution'] === undefined)
    ) {
      this.setState({
        errors: this.props.t('errorDistrib')
      });
    } else if (this.state['accept_tc'] === false || this.state['accept_tc'] === undefined) {
      this.setState({
        errors: this.props.t('errorCondVentes')
      });
    } else if (
      this.state.selectedCountry.currency === 'EUR' &&
      this.state['packPrice-mode'] === 'wari'
    ) {
      this.setState({
        errors: this.props.t('errorYoutrace')
      });
    } else if (this.state['packPrice-mode'] === undefined) {
      this.setState({ errors: this.props.t('errorModePay') });
    } else {
      if (payment != null && this.state['packPrice-mode'] !== 'touchpay') {
        this.setState({ paypalPayment: payment });
        this.createClip();
      } else if (payment === true || this.state['packPrice-mode'] != 'paypal') {
        console.log("sfsdf");
        let order =  await this.createClip();
        return order;
      }
    }
  }

  createClip() {
    let services = [];
    if (this.state.optionDiffusion) {
      services.push('UPLOAD');
    }
    if (this.state.optionPromotion) {
      services.push(this.promo());
    }
    if (this.state.optionDistribution) {
      services.push('DISTRIBUTION');
    }
    if (this.state.optionRealisation) {
      services.push('PACK_' + this.state['option-realisation'].toUpperCase());
    }
    return api.createClip(
      {
        pack: this.state['option-realisation']
          ? this.state['option-realisation'].toUpperCase()
          : this.state.optionDiffusion
          ? 'UPLOAD'
          : this.promo(),
        production: this.state.optionRealisation,
        promotion: this.state.optionPromotion,
        amount:
          Number(this.getPackPrice()) +
          Number(this.getPromoPrice()) +
          Number(this.getDiffusionPrice()),
        producer: this.state.producer,
        producerStreet: this.state.producerStreet,
        producerCity: this.state.producerCity,
        producerCountry: this.state.producerCountry,
        artist: this.state.artisteName,
        title: this.state.titleName,
        country: this.state.selectedCountry,
        label: this.state.label,
        diffusion: this.state.diffusionDate ? this.diffusion() : null,
        url: this.state.clipUrl,
        orderServices: services,
        idTransaction: this.state['packPrice-mode'] === 'paypal' ? this.state.paypalPayment.paymentID : '000',
        paymentMethod: this.state['packPrice-mode']
      },
      clip => this.onCreateClip(clip)
    );
  }

  promo() {
    if (this.state['option-diffusion'] === 'youtrace') {
      return 'PROMO_CLASSIC';
    } else if (this.state['option-diffusion'] === 'youtrace-trace') {
      return 'PROMO_PREMIUM';
    }
  }

  diffusion() {
    let diffusion = this.state.diffusionDate;
    let date = ('0' + diffusion.getDate()).slice(-2);
    let month = ('0' + (diffusion.getMonth() + 1)).slice(-2);
    return date + '/' + month + '/' + diffusion.getFullYear();
  }

  handleCCProd(locale) {
    api.ccprod2(
      {
        pack: this.state['option-realisation']
          ? this.state['option-realisation'].toUpperCase()
          : this.state.optionDiffusion
          ? 'UPLOAD'
          : 'PROMO',
        amount:
          Number(this.getPackPrice()) +
          Number(this.getPromoPrice()) +
          Number(this.getDiffusionPrice()),
        producer: this.state.producer,
        producerStreet: this.state.producerStreet,
        producerCity: this.state.producerCity,
        producerCountry: this.state.producerCountry,
        artist: this.state.artisteName,
        title: this.state.titleName,
        country: this.state.selectedCountry,
        label: this.state.label,
        signed: this.state['accept_monetisation']
      },
      blob => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', 'Music_Video_Coproduction_Contract.pdf');
        document.body.appendChild(link);
        link.click();
      },
      locale
    );
  }

  handleCgu(locale) {
    api.cgv(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', 'Terms_And_Conditions.pdf');
      document.body.appendChild(link);
      link.click();
    }, locale);
  }

  handleCDist(locale) {
    api.dist(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', 'Digital_Distribution_Contract.pdf');
      document.body.appendChild(link);
      link.click();
    }, locale);
  }

  onCreateClip(clip) {
    let isTouchPayment = this.state['packPrice-mode'] === 'touchpay';
    this.setState({ payment: isTouchPayment ? false : true, 
    recap: isTouchPayment ? true : false, 
    createdClip: clip });
  }

  render() {
    if (localStorage.getItem('access_token')) {
      return (
        <>
          {this.state.services && (
            <Services
              {...this.state}
              onChangeCountry={this.handleCountryChange}
              onChange={this.handleChange}
              goToInformations={this.goToInformations}
            />
          )}
          {this.state.informations && (
            <Informations
              {...this.state}
              handleSubmit={this.handleSubmit}
              onChange2={this.handleChange}
              goToRecap={this.goToRecap}
              goToServices={this.goToServices}
              onChange={this.handleChange}
              onSuggestions={this.onSuggestions}
              onCountryChange={this.handleProducerCountryChange}
              goToCalendar={this.goToCalendar}
            />
          )}
          {this.state.calendar && (
            <Calendar
              {...this.state}
              handleSubmit={this.handleSubmit}
              goToInformations={this.goToInformations}
              goToRecap={this.goToRecap}
              handleDate={this.handleDate}
              disabledDates={this.state.disabledDates}
            />
          )}
          {(this.state.recap || this.state['packPrice-mode'] === 'touchpay')&& (
            <Recap
              {...this.state}
              pay={this.pay}
              env={this.state.user.connectedOn === 'production' ? 'production' : 'sandbox'}
              user={this.state.user}
              getPackPrice={this.getPackPrice}
              handleCCProd={this.handleCCProd}
              handleCgv={this.handleCgu}
              handleCDist={this.handleCDist}
              getPromoPrice={this.getPromoPrice}
              getDiffusionPrice={this.getDiffusionPrice}
              onChange={this.handleChange}
              goToServices={this.goToServices}
              goToInformations={this.goToInformations}
              turnOffDiffusion={this.turnOffDiffusion}
            />
          )}
          {this.state.payment && this.state['packPrice-mode'] != 'touchpay' && this.state['packPrice-mode'] != 'wari' && (
            <Coordinateur {...this.state} />
          )}
          {this.state.payment && this.state['packPrice-mode'] === 'wari' && (
            <Wari {...this.state} />
          )}
        </>
      );
    } else {
      return <Redirect to={'/connexion'} />;
    }
  }
}

export default withTranslation()(Subscription);
