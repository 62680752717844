import styled, { css } from 'styled-components';
import { space } from 'styled-system';

const Title1 = styled.h1`
	font-weight: 600;
	font-size: 40px;
	${space}
	span {
		color: ${props => props.theme.colors.orange};
	}
	${props =>
  props.orange &&
  css`
      color: ${props => props.theme.colors.orange};
    `}

	@media ${props => props.theme.mediaQueries.smallWidth} {
		font-size: 36px
	}
`;
const Title2 = styled.h2`
	font-weight: 600;
	font-size: 32px;
	letter-spacing: -0.76px;
	${space}

	@media ${props => props.theme.mediaQueries.smallWidth} {
		font-size: 26px;
	}
	span {
		color: ${props => props.theme.colors.orange};
	}
	${props =>
  props.orange &&
  css`
      color: ${props => props.theme.colors.orange};
    `}
	${props =>
  props.bold &&
  css`
      font-weight: bold;
    `}
	${props =>
  props.light &&
  css`
      font-weight: 300;
    `}
`;
const Title3 = styled.h3`
	font-weight: 600;
	font-size: 32px;
	letter-spacing: -0.76px;
	${space}

	@media ${props => props.theme.mediaQueries.smallWidth} {
		font-size: 26px;
	}
	span {
		color: ${props => props.theme.colors.orange};
	}

	${props =>
  props.bold &&
  css`
      font-weight: bold;
    `}
	${props =>
  props.orange &&
  css`
      color: ${props => props.theme.colors.orange};
    `}
`;
const Title4 = styled.h4`
	font-weight: 400;
	font-size: 25px;
	letter-spacing: -0.76px;
	${space}

	@media ${props => props.theme.mediaQueries.smallWidth} {
		font-size: 20px;
	}
	span {
		color: ${props => props.theme.colors.orange};
	}

	${props =>
  props.bold &&
  css`
      font-weight: bold;
    `}
	${props =>
  props.orange &&
  css`
      color: ${props => props.theme.colors.orange};
    `}
`;
const Title5 = styled.h4`
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.76px;
  span {
    color: ${props => props.theme.colors.orange};
  }

  ${props =>
  props.orange &&
  css`
      color: ${props => props.theme.colors.orange};
    `}
`;

export { Title1, Title2, Title3, Title4, Title5 };
