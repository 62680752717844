import React from 'react';

import Container, { Box } from '../styles/Container';
import Button from '../styles/Button';
import { Form, FormCol } from '../styles/Form';
import { Text } from '../styles/Texts';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PasswordInput from '../form/Password';
import CountryDropdown from '../form/CountryDropdown';
import Link from '../styles/Link';
import InputText from '../form/InputText';
import { useTranslation } from 'react-i18next';

export default function ModifyInfo({
  toggleModifying,
  onChange,
  onChangePassword,
  onSuggestions,
  onCountryChange,
  handleDeleteAccount,
  user
}) {
  const { t } = useTranslation();
  const SignUpSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('firstNameLeast'))
      .max(20, t('firstNameMax'))
      .required(t('firstNameEnter')),
    lastName: Yup.string()
      .min(2, t('surnameLeast'))
      .max(20, t('surnameMax'))
      .required(t('surnameEnter')),
    phone: Yup.string()
      .min(6, t('phoneLeast'))
      .max(15, t('phoneMax'))
      .required(t('phoneEnter'))
      .matches(/^[+]/, {
        message: t('phoneCheck'),
        excludeEmptyString: true
      })
      .matches(
        /^[+]((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        {
          message: t('phoneInvalid'),
          excludeEmptyString: true
        }
      ),
    password: Yup.string()
      .min(8, t('passwordLeast'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t('passwordIncorrect')),
    country: Yup.string().test('country', t('countryEnter'), () => user.country != null)
  });
  return (
    <>
      <Formik
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone
        }}
        validationSchema={SignUpSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          toggleModifying();
        }}
      >
        {({ errors, handleChange, handleSubmit }) => (
          <>
            <Container small mt={4}>
              <Form>
                <FormCol>
                  <InputText
                    name="lastName"
                    label={t('registration.surname') + ' :'}
                    value={user.lastName || ''}
                    handleChange={e => {
                      onChange(e, 'lastName');
                      handleChange(e);
                    }}
                  />
                  {errors.lastName && <Text error>{errors.lastName}</Text>}
                </FormCol>
                <FormCol>
                  <InputText
                    name="firstName"
                    label={t('registration.firstName') + ' :'}
                    value={user.firstName || ''}
                    handleChange={e => {
                      onChange(e, 'firstName');
                      handleChange(e);
                    }}
                  />
                  {errors.firstName && <Text error>{errors.firstName}</Text>}
                </FormCol>
                <FormCol>
                  <InputText
                    name="phone"
                    label={t('registration.phone') + ' :'}
                    value={user.phone || ''}
                    handleChange={e => {
                      onChange(e, 'phone');
                      handleChange(e);
                    }}
                  />
                  {errors.phone && <Text error>{errors.phone}</Text>}
                </FormCol>
                <FormCol>
                  <PasswordInput
                    label={t('registration.password') + ' :'}
                    name="password"
                    onChange={onChangePassword}
                    handleChange={handleChange}
                  />
                  {errors.password && <Text error>{errors.password}</Text>}
                </FormCol>
                <FormCol>
                  <CountryDropdown
                    label={t('registration.country') + ' :'}
                    value={user.country.country}
                    onSuggestions={onSuggestions}
                    onCountryChange={onCountryChange}
                  />
                  {errors.country && <Text error>{errors.country}</Text>}
                </FormCol>
                <FormCol dSNone />
                <Box center>
                  <Box mb={3}>
                    <Button fullWidth orange onClick={handleSubmit} mb={2}>
                      {t('save')}
                    </Button>
                  </Box>
                  <Link onClick={handleDeleteAccount} red>
                    {t('deleteMyAccount')}
                  </Link>
                </Box>
              </Form>
            </Container>
          </>
        )}
      </Formik>
    </>
  );
}
