import styled from 'styled-components';
import Background from '../../../img/menu-dark.jpg';
import BackgroundLight from '../../../img/menu-light.jpg';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  transition: 0.6s ${props => props.theme.transitions.easeInOutCubic};
  transform: ${({ open }) => (open ? 'scaleX(1)' : 'scaleX(0)')};
  transform-origin: right;
  z-index: 9999;
  width: 100%;
  background-color: ${props => props.theme.colors.primary};
  background-image: url(${({ darkTheme }) => (darkTheme ? Background : BackgroundLight)});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  overflow: auto;
  @media ${props => props.theme.mediaQueries.minSmallWidth} {
    display: none;
  }

  a {
    font-size: 19px;
    margin: 0 0 1.5rem 0;
    color: ${props => props.theme.colors.secondary};
    opacity: 1;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: left;

    &:hover {
      opacity: 0.7;
    }

    i {
      color: ${props => props.theme.colors.secondary};
      font-size: 18px;
      margin-right: 1rem;
      padding: 0.5rem 0.5rem 0.5rem 0;
      border-right: 1px solid;
      min-width: 40px;
      display: inline-block;
    }
  }
`;
