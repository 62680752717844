import styled from 'styled-components';

export const Service = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  min-height: 464px;
  width: 100%;
  overflow: hidden;
  text-align: left;
  color: ${props => props.theme.colors.secondary};
  text-decoration: none;
  @media ${props => props.theme.mediaQueries.mediumWidth} {
    text-align: center;
    padding: 0 20px;
    min-height: 380px;
  }
  .button {
    margin: 10px 0;
    color: #ffffff;
  }
  a {
    color: ${props => props.theme.colors.orange};
    text-decoration: none;
    transition: all 0.4s;
    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }
  .image {
    position: relative;
    i {
      position: absolute;
      color: ${props => props.theme.colors.orange};
      font-size: 100px;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);
      @media ${props => props.theme.mediaQueries.smallWidth} {
        left: 50%;
        right: auto;
        transform: translate(50%, -50%);
      }
    }
    img {
      max-width: 550px;
      @media ${props => props.theme.mediaQueries.mediumWidth} {
        margin: auto;
      }
    }
    &.video {
      cursor: pointer;
    }
  }
  @media ${props => props.theme.mediaQueries.mediumWidth} {
    text-align: center;
  }
  .content {
    flex: 2;
    min-width: 300px;
    position: relative;
    margin: 0 25px;
    @media ${props => props.theme.mediaQueries.mediumWidth} {
      text-align: center;
      margin-left: 0;
      margin: auto;
    }
  }
  &:nth-child(odd) {
    flex-direction: row-reverse;
    .content {
      text-align: right;
      @media ${props => props.theme.mediaQueries.mediumWidth} {
        text-align: center;
      }
    }
  }
  .title {
    font-weight: bold;
    margin-bottom: 0;
  }
`;
