import React from 'react';

import Button from '../styles/Button';
import { Text } from '../styles/Texts';
import Container, { Box } from '../styles/Container';
import { Title4 } from '../styles/Titles';

export default function Deleted() {
  return (
    <Container small mt={4}>
      <Box center>
        <Title4>Ton compte a bien été supprimé.</Title4>
        <Text>
          <br/> Tu peux toutefois te recréer un compte à tout moment.
          <br/> Également, n'hésites pas à nous contacter si tu as des questions.
        </Text>
        <Button mt={4} href="/">
          Aller sur la page d'accueil
        </Button>
      </Box>
    </Container>
  );
}
