import React, { Component } from 'react';

import { Box } from '../Components/styles/Container';
import { Title4 } from '../Components/styles/Titles';
import { Text } from '../Components/styles/Texts';
import Banner from '../Components/Banner';
import imgCover from '../img/confirmation.jpg';
import { AvantageSlider } from '../Components/Avantages';
import ScrollToTop from '../Components/ScrollToTop';

class Wari extends Component {
  render() {
    return (
      <>
        <ScrollToTop />
        <Banner
          children={
            <>
              <Box center>
                <Title4 bold>
                  Cher(e) <span>{this.props.user.firstName}</span>,
                  <br /> Merci pour ta commande.
                </Title4>
              </Box>
              <Text mb={4}>
                Tu peux dès à présent te rendre dans n’importe quelle agence Wari (www.wari.com)
                pour payer ta commande en utilisant la référence suivante :
                <Text my={2} bold orange center>
                  {this.props.createdClip.cru}
                </Text>
              </Text>
              <Text mb={4}>
                Une fois ton paiement effectué le représentant officiel de YouTrace pour ta région,
                prendra contact avec toi au numéro suivant :
                <Text my={2} bold orange center>
                  {this.props.user.phone}
                </Text>
              </Text>
              <Text mb={4}>
                Toutes ces informations ont aussi été envoyées sur ton mail :
                <Text my={2} bold orange center>
                  {this.props.user.emailAddress}
                </Text>
                {this.props.user.optionDistribution && (
                  <Text>
                    Tu peux tout de suite commencer à vendre ta musique en cliquant{' '}
                    <a
                      href="https://distribution.pro.youtrace.tv/albums"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ici
                    </a>
                    .
                  </Text>
                )}
                <br /> Si tu as des questions, n’hésites pas à nous écrire à :{' '}
                <Text my={2} bold orange center>
                  contact@youtrace.tv
                </Text>
              </Text>
              <Text>À très vite sur YouTrace.</Text>
            </>
          }
          img={imgCover}
          alt="YouTrace confirmation"
          imgHeight="500px"
          cta
        />
        <AvantageSlider />
      </>
    );
  }
}

export default Wari;
