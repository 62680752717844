/*eslint eqeqeq: 0*/
import React, { Component } from 'react';

import { Box } from '../styles/Container';
import { BannerWrapper } from '../Banner';
import trailerVideo from '../../img/trailer.mp4';
import Whoami from './Whoami';
import { AvantageSlider } from '../Avantages';
import api from '../../client/api';
import auth from '../../auth/auth';
import VideoWrapper, { Filter } from '../styles/VideoWrapper';

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastName: '',
      firstName: '',
      tel: '',
      lastPhone: '',
      emailAddress: '',
      bornDate: '',
      password: '',
      country: null,
      error: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.register = this.register.bind(this);
    this.onSuggestions = this.onSuggestions.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  register() {
    api.register(
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        emailAddress: this.state.emailAddress,
        password: this.state.password,
        countryCode: this.state.country.alpha_2,
        phone: this.state.tel
      },
      this.onRegister,
      e => {
        //console.log(e);
      }
    );
  }

  onRegister(data) {
    if (data.message && data.message.includes('Response status 406 with reason')) {
      this.setState({ errors: 'Cette adresse e-mail est déjà utilisée' });
    } else {
      auth.fetchAccessToken(
        () => {
          window.location.href = '/confirmation';
        },
        e => {
          //console.log(e);
        },
        this.state.emailAddress,
        this.state.password
      );
    }
  }

  onSuggestions(suggestions) {
    if (suggestions.length === 1) {
      if (this.state.country != suggestions[0]) {
        this.setState({
          country: suggestions[0],
          lastPhone: '+' + suggestions[0].phone_code,
          tel: this.state.tel
            .toString()
            .replace(this.state.lastPhone, '+' + suggestions[0].phone_code)
        });
      }
    } else {
      this.setState({ country: null });
    }
  }

  handleCountryChange(country) {
    if (this.state.country != country[0]) {
      this.setState({
        country: country[0],
        lastPhone: '+' + country[0].phone_code,
        tel: this.state.tel.toString().replace(this.state.lastPhone, '+' + country[0].phone_code)
      });
    }
  }

  handleChange(e, field) {
    if (field === 'emailAddress') {
      this.setState({ errors: null });
    }
    if (field === 'tel') {
      let regExpMatchArray = e.target.value.toString().match('^\\+[0-9]*|[0-9]*$');
      if (regExpMatchArray[0].length === e.target.value.length) {
        this.setState({ [field]: e.target.value });
      }
    } else {
      switch (e.target.type) {
        case 'checkbox':
          this.setState({ [field]: e.target.checked });
          break;
        case 'radio':
          this.setState({ [field]: e.target.id });
          break;
        default:
          this.setState({ [field]: e.target.value });
      }
    }
  }

  render() {
    return (
      <>
        <BannerWrapper>
          <VideoWrapper
            dangerouslySetInnerHTML={{
              __html: `
									<video playsInline autoPlay muted loop>
										<source src="${trailerVideo}" type='video/mp4' />
									</video>
								`
            }}
          ></VideoWrapper>
          <Filter />
          <Box py={4}>
            <Whoami
              {...this.state}
              {...this.props}
              onChange={this.handleChange}
              onCountryChange={this.handleCountryChange}
              onSuggestions={this.onSuggestions}
              onSuggestionsPhone={this.onSuggestionsPhone}
              register={this.register}
            />
          </Box>
        </BannerWrapper>
        <AvantageSlider />
      </>
    );
  }
}

export default RegistrationForm;
