import React from 'react';
import { useTranslation } from 'react-i18next';

import Container, { Box } from '../Components/styles/Container';
import { en, pt, fr } from '../lang/mentions-legales';

export const MentionsLegales = () => {
  const { i18n } = useTranslation();
  const acceptableLanguages = {
    fr: fr,
    en: en,
    pt: pt
  };
  let lang = i18n.language.slice(0, 2);
  return (
    <Box py={[2, 3]} pb={4}>
      <Container>
        <Box mb={5} dangerouslySetInnerHTML={{ __html: acceptableLanguages[lang] }} />
      </Container>
    </Box>
  );
};

export default MentionsLegales;
