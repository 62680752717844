import React, { Component } from 'react';
import { Swipeable } from 'react-swipeable';
import Loader from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';

import { Title2, Title4 } from '../../Components/styles/Titles';
import Container, { Box, Flex } from '../../Components/styles/Container';
import Button from '../../Components/styles/Button';
import Link from '../../Components/styles/Link';
import SimpleLine from '../../Components/dashboard/graphics/SimpleLine';
import Donut from '../../Components/dashboard/graphics/Donut';
import api from '../../client/api';
import months from '../../utils/months';
import { activeLang } from '../../utils/translate';
import { userContext } from '../../context/userContext';
import { fixerApiKey } from '../../config/config';

class ChiffreDaffaire extends Component {
  constructor(props) {
    const t = new Date();
    super(props);
    this.state = {
      caGlobal: null,
      annuel: false,
      month: t.getMonth(),
      year: t.getYear() + 1900
    };
    this.switchView = this.switchView.bind(this);
    this.prevMonth = this.prevMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.convertCurrency = this.convertCurrency.bind(this);
  }

  convertCurrency(data) {
    return (data.caGlobal[this.state.year] = data.caGlobal[this.state.year].map(item => {
      item.data = item.data.map(el => {
        el = Math.round((el * this.state.currencyRate + Number.EPSILON) * 100) / 100;
        return el;
      });
      return item;
    }));
  }

  componentDidMount() {
    fetch(`https://data.fixer.io/api/latest?access_key=${fixerApiKey}`)
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(data => {
        this.setState({ currencyRate: data.rates[this.props.currency] });
      })
      .then(data => {
        api.ca(data => {
          this.convertCurrency(data);
          this.setState({
            caGlobal: data.caGlobal[this.state.year]
          });
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.year !== prevState.year) {
      api.ca(data => {
        this.convertCurrency(data);
        this.setState({ caGlobal: data.caGlobal[this.state.year] });
      });
    }
  }

  switchView() {
    this.setState(prevState => ({
      annuel: !prevState.annuel
    }));
  }

  prevMonth() {
    if (this.state.month > 0) {
      this.setState(prevState => ({
        month: prevState.month - 1
      }));
    } else if (this.state.month === 0 && this.state.year > 2020) {
      this.setState(prevState => ({
        month: 11,
        year: prevState.year - 1
      }));
    }
  }

  nextMonth() {
    const d = new Date();
    if (this.state.month < d.getMonth()) {
      this.setState(prevState => ({
        month: prevState.month + 1
      }));
    } else if (this.state.month > d.getMonth()) {
      this.setState(prevState => ({
        month: 0,
        year: prevState.year + 1
      }));
    }
  }

  render() {
    const { month, year, caGlobal, annuel } = this.state;
    const { currency } = this.props;
    const theme = true;
    return (
      <Container>
        <Box py={4} center>
          <Title2 dangerouslySetInnerHTML={{ __html: this.props.t('bo.yourca') }} />
        </Box>
        {annuel ? (
          <Box mb={4} center>
            <Title4 bold>{this.props.t('bo.yearca')}</Title4>
            <SimpleLine theme={theme} chartData={caGlobal} />
          </Box>
        ) : (
          <Swipeable onSwipedRight={() => this.prevMonth()} onSwipedLeft={() => this.nextMonth()}>
            <Box mb={4} center>
              <Flex justifyContent="space-between">
                <i
                  style={{ cursor: 'pointer' }}
                  className="icon-chevron-left"
                  onClick={this.prevMonth}
                />
                <Title4 bold mt={0}>
                  {months[activeLang][month] + ' ' + year}
                </Title4>
                <i
                  style={{ cursor: 'pointer' }}
                  className="icon-chevron-right"
                  onClick={this.nextMonth}
                />
              </Flex>
              {caGlobal ? (
                <Donut theme={theme} data={caGlobal} month={month} unit={currency} />
              ) : (
                <Loader
                  type="TailSpin"
                  color="#fff"
                  height={25}
                  width={25}
                  style={{ textAlign: 'center', margin: '5rem' }}
                />
              )}
            </Box>
          </Swipeable>
        )}
        <Box my={4} center>
          {this.state.annuel ? (
            <Button fullWidth mb={4} href="#" onClick={this.switchView}>
              {this.props.t('bo.caservice') + ' ' + this.props.t('mensual')}
            </Button>
          ) : (
            <Button fullWidth mb={4} href="#" onClick={this.switchView}>
              {this.props.t('bo.caservice') + ' ' + this.props.t('annual')}
            </Button>
          )}
          <Box>
            <Link orange href="/dashboard">
              Dashboard
            </Link>
          </Box>
        </Box>
      </Container>
    );
  }
}
export default withTranslation()(
  React.forwardRef((props, ref) => (
    <userContext.Consumer>
      {value => <ChiffreDaffaire {...props} value={value} ref={ref} />}
    </userContext.Consumer>
  ))
);
