import React from 'react';
import { Box } from '../styles/Container';
import { Text } from '../styles/Texts';
import { Title5 } from '../styles/Titles';

import { StepsWrap, StepsItem, StepsText } from './steps.style';

function Steps({ itemsList }) {
  return (
    <StepsWrap>
      {itemsList.map((el, i) => (
        <StepsItem key={i}>
          <Box>
            <img src={el.icon} alt="step" />
          </Box>
          <StepsText>
            <Title5>{el.title}</Title5>
            <Text dangerouslySetInnerHTML={{ __html: el.paragraph }} />
          </StepsText>
        </StepsItem>
      ))}
    </StepsWrap>
  );
}

export default Steps;
