import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Social, FooterTop, FooterNav } from './Footer.style';
import { Box, Flex } from '../../styles/Container';

function Footer() {
  const { t } = useTranslation();
  return (
    <div data-testid="footer" target="_blank" style={{ zIndex: 999999 }}>
      <FooterTop>
        <a href="https://youtrace.tv/" className="b2c" target="_blank" rel="noopener noreferrer">
          {t('backTo')} <span className="b2c-orange">YouTrace</span>
        </a>
        <Box>
          <Social
            href="https://www.facebook.com/youtrace.officiel/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-facebook"></i>
          </Social>{' '}
          <Social
            href="https://www.instagram.com/youtrace_officiel/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-instagram"></i>
          </Social>
          <Social
            href="https://www.youtube.com/channel/UCQbbxLP4yfIIy9exVtdipUQ/?sub_confirmation=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-youtube"></i>
          </Social>
          <Social href="https://twitter.com/YouTrace_off" target="_blank" rel="noopener noreferrer">
            <i className="icon-twitter"></i>
          </Social>
        </Box>
      </FooterTop>
      <FooterNav>
        <Flex justifyContent="space-between">
          <Box mb={1}>
            <Link to="/foire-aux-questions"> FAQ </Link>
            <Link to="/mentions-legales"> {t('legalNotices')} </Link>
          </Box>
          <Box>
            <Flex
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              style={{ cursor: 'pointer' }}
            >
              {t('backToTop')}
              <i
                className="icon-chevron-bottom"
                style={{ transform: 'rotate(180deg)', display: 'block', marginLeft: 15 }}
              />
            </Flex>
          </Box>
        </Flex>
      </FooterNav>
    </div>
  );
}

Footer.contextTypes = {
  router: PropTypes.object
};
export default Footer;
