import styled from 'styled-components';

export const ArtistsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
export const ArtistItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 125px;
  height: 125px;
  overflow: hidden;
  img {
    max-width: 100%;
    filter: brightness(100%);
    transition: filter 0.6s;
  }
  p {
    position: absolute;
    width: 100%;
    text-align: center;
    transform: translateY(125px);
    transition: all 0.6s;
  }
  &:hover {
    img {
      filter: brightness(50%);
    }
    p {
      transform: translateY(0);
    }
  }
`;
