import styled from 'styled-components';

export const ServicesWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;
export const ServicesItem = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px 20px;
  overflow: hidden;
  min-width: 200px;
  img {
    width: 100%;
    filter: brightness(100%);
    transition: filter 0.4s;
    @media ${props => props.theme.mediaQueries.mediumWidth} {
      filter: brightness(50%);
    }
  }
  &:hover {
    img {
      filter: brightness(50%);
    }
    div {
      opacity: 1;
      transform: translateY(-20px);
    }
  }
`;
export const HoverOnly = styled.div`
  position: absolute;
  transition: all 0.6s;
  @media ${props => props.theme.mediaQueries.minMediumWidth} {
    transform: translateY(200%);
    opacity: 0;
  }
`;
