import React, { Component } from 'react'
import api from '../client/api'
import ConfirmationRender from "./ConfirmationRender";

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    }
  }

  componentDidMount() {
    api.getUser(user => {
      this.setState({ user: user })
    })
  }

  render() {
    return (
      <ConfirmationRender user={this.state.user}/>
    )
  }
}

export default Confirmation;
