import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import Container, { Box } from './styles/Container';
import { Title2 } from './styles/Titles';
import { Avantage, AvantagesWrapper } from './Avantages';
import '../vendors/slick.css';
import '../vendors/slick-theme.css';

import imgOffer from '../img/cover-trace.jpg';
import iconSecurite from '../img/securite.svg';
import iconNotoriete from '../img/notoriete.svg';
import iconCoordinateur from '../img/coordinateur.svg';
import iconArgument from '../img/clip.svg';
import { useTranslation } from 'react-i18next';

const BannerWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 80px;
  text-align: center;
  min-height: 170px;
  padding: 100px 0;

  background: #000;
  color: #fff;
  @media ${props => props.theme.mediaQueries.smallWidth} {
    padding: 50px 0;
    margin-bottom: 40px;
  }
`;
const BannerImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
  z-index: 0;
  left: 0;
`;

function Banner() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };
  const { t } = useTranslation();
  const avantages = [
    {
      icon: iconSecurite,
      title: t('offer1')
    },
    {
      icon: iconNotoriete,
      title: t('offer2')
    },
    {
      icon: iconCoordinateur,
      title: t('offer3')
    },
    {
      icon: iconArgument,
      title: t('offer4')
    }
  ];
  return (
    <BannerWrapper style={{ minHeight: '300px' }}>
      <BannerImg src={imgOffer} alt="YouTrace Pro"/>
      <Container>
        <Title2>
          YouTrace <span>Pro</span>
        </Title2>
        <Box>
          <AvantagesWrapper>
            <Slider {...settings}>
              {avantages.map((avantage, i) => (
                <Avantage key={i} {...avantage} />
              ))}
            </Slider>
          </AvantagesWrapper>
        </Box>
      </Container>
    </BannerWrapper>
  );
}

export default Banner;
