/*eslint eqeqeq: 0*/
const FetchInterceptor = require('fetch-interceptor');

// Register interceptor hooks
export default FetchInterceptor.register({
  onBeforeRequest(request, controller) {},
  onRequestSuccess(response, request, controller) {
    //console.log(response);
  },
  onRequestFailure(response, request, controller) {
    //console.log(response);
    if (response.status == 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('idRole');
      localStorage.removeItem('expires_in');
      window.location.href = '/';
    }
  }
});
