import React, { Component } from 'react';

import { Box } from '../Components/styles/Container';
import { Title4 } from '../Components/styles/Titles';
import { Text } from '../Components/styles/Texts';
import Banner from '../Components/Banner';
import imgCover from '../img/confirmation.jpg';
import { AvantageSlider } from '../Components/Avantages';
import ScrollToTop from '../Components/ScrollToTop';
import { withTranslation } from 'react-i18next';

class Coordinateur extends Component {
  render() {
    return (
      <>
        <ScrollToTop />
        <Banner
          children={
            <>
              <Box center>
                <Title4 bold>
                  <span>{this.props.user.firstName}</span>,
                  <br /> {this.props.t('confirmation.thanks')}
                </Title4>
              </Box>
              <Text mb={4}>
                {this.props.selectedCountry.coordinator.firstName}{' '}
                {this.props.selectedCountry.coordinator.lastName},{' '}
                {this.props.t('confirmation.representant')} :
              </Text>
              <Text my={2} bold orange center>
                {this.props.user.phone}
              </Text>
              <Text>{this.props.t('confirmation.email')} :</Text>
              <Text my={2} bold orange center>
                {this.props.user.emailAddress}
              </Text>
              <Text mb={4}>{this.props.t('confirmation.prove')}</Text>
              {this.props.optionDistribution && (
                <Text dangerouslySetInnerHTML={{ __html: this.props.t('confirmation.sell') }} />
              )}
              <br /> {this.props.t('confirmation.questions')} :{' '}
              <Text my={2} bold orange center>
                contact@youtrace.tv
              </Text>
            </>
          }
          img={imgCover}
          alt="Confirmation"
          imgHeight="500px"
          cta
        />
        <AvantageSlider />
      </>
    );
  }
}

export default withTranslation()(Coordinateur);
