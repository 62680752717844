import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationEng from './lang/en.json';
import translationFr from './lang/fr.json';
import translationPt from './lang/pt.json';

i18n
.use(XHR)
.use(LanguageDetector)
.init({
  //debug: true,
  //lng: "fr",
  fallbackLng: 'en', // use en if detected lng is not available

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false // react already safes from xss
  },

  react: {
    useSuspense: false
  },

  resources: {
    en: {
      translations: translationEng
    },
    fr: {
      translations: translationFr
    },
    pt: {
      translations: translationPt
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

export default i18n;
