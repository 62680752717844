import React from 'react';
import uuid from 'uuid';

import { Box } from '../styles/Container';
import { Input, Switcher, SwitchItem } from '../styles/Form';
import { Text, TextL, TextS } from '../styles/Texts';
import bgUrl from '../../img/promo-digitale.jpg';
import { numberToDevice } from '../subscription/utils';
import { useTranslation } from 'react-i18next';

export default function Options({ onChange, isMobile, ...props }) {
  function handleCheckboxes(checked, field) {
    const e = {
      target: {
        type: 'checkbox',
        checked
      }
    };
    onChange(e, field);
  }

  const checked = props['optionDiffusion'] || false;
  const { t } = useTranslation();
  return (
    <>
      <SwitchItem
        key={uuid()}
        style={{ backgroundImage: `url(${bgUrl || ''})` }}
        onClick={() => handleCheckboxes(!checked, 'optionDiffusion')}
        className={checked && 'checked'}
        tShadow
      >
        <Box className="overlay" />
        <TextL>{t('shareMyVideo.title')}</TextL>
        <Switcher>
          <Input
            onChange={() => handleCheckboxes(!checked, 'optionDiffusion')}
            type="checkbox"
            id="optionDiffusion"
            name="radio"
            checked={checked}
          />
          <label htmlFor="optionDiffusion">
            <span></span>
          </label>
        </Switcher>
        <Box fWidth pt={3}>
          <Text dangerouslySetInnerHTML={{ __html: t('shareMyVideo.resume') }} />
          {props['optionRealisation'] && (
            <Text orange py={1}>
              {t('shareMyVideo.free')}
            </Text>
          )}
        </Box>
      </SwitchItem>
      {props['optionDiffusion'] && (
        <Box border my={3} mb={4} p={3}>
          {!props['optionRealisation'] && (
            <TextS style={{ textAlign: 'right' }}>
              {numberToDevice(
                props['selectedCountry'].countryServices.find(
                  cs => cs.service.serviceType === 'UPLOAD'
                ).amount
              ) +
                ' ' +
                props['selectedCountry'].currency}
            </TextS>
          )}
          <ul className="tick-list">
            <li>
              <TextS>{t('shareMyVideo.desc1')}</TextS>
            </li>
            <li>
              <TextS>{t('shareMyVideo.desc2')}</TextS>
            </li>
            <li>
              <TextS>{t('shareMyVideo.desc3')}</TextS>
            </li>
          </ul>
        </Box>
      )}
    </>
  );
}
