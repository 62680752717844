import { Box } from '../styles/Container';
import { Title2 } from '../styles/Titles';
import { userContext } from '../../context/userContext';
import { Formik } from 'formik';
import { Form, FormCol } from '../styles/Form';
import InputText from '../form/InputText';
import { Text, TextS } from '../styles/Texts';
import PasswordInput from '../form/Password';
import Button from '../styles/Button';
import Link from '../styles/Link';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export default function({ handleLogin, onChange, resetPasswordHandler, error, ...props }) {
  const { t } = useTranslation();
  const SignUpSchema = Yup.object().shape({
    login: Yup.string()
      .min(5, t('loginLeast'))
      .max(50, t('loginMax'))
      .required(t('completeField')),
    password: Yup.string()
      //.min(8, t('passwordLeast'))
      .required(t('completeField'))
      //.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t('passwordIncorrect'))
  });
  return (
    <>
      <Box p={4}>
        <Title2 bold>{t('login')}</Title2>
      </Box>
      <userContext.Consumer>
        {({ loginUser }) => {
          return (
            <Formik
              initialValues={{
                login: '',
                password: ''
              }}
              validationSchema={SignUpSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={values => {
                handleLogin(loginUser);
              }}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <Form white>
                  <FormCol>
                    <InputText
                      name="login"
                      label={t('registration.email') + ' :'}
                      value={values.login}
                      handleChange={e => {
                        onChange(e, 'login');
                        handleChange(e);
                      }}
                    />
                    {errors.login && <Text error>{errors.login}</Text>}
                  </FormCol>
                  <FormCol>
                    <PasswordInput
                      name="password"
                      onChange={onChange}
                      values={values}
                      label={t('registration.password') + ' :'}
                      handleChange={handleChange}
                    />
                    {errors.password && <Text error>{errors.password}</Text>}
                  </FormCol>
                  <FormCol mt={3} mx="auto">
                    {error && <Text error>{t('loginError')}</Text>}
                    <Button onClick={handleSubmit}>{t('login.connect')}</Button>

                    <Box mt={4} center>
                      <Link orange href="/inscription">
                        {t('register')}
                      </Link>
                    </Box>
                    <Box mt={2} center>
                      <TextS onClick={() => resetPasswordHandler()} pointer>
                        {t('login.lost')}
                      </TextS>
                    </Box>
                  </FormCol>
                </Form>
              )}
            </Formik>
          );
        }}
      </userContext.Consumer>
    </>
  );
}
