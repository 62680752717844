import React, { Component } from 'react';
import styled from 'styled-components';

import { Input } from '../styles/Form';

const ShowHide = styled.i`
  position: absolute;
  cursor: pointer;
  color: ${props => props.theme.colors.secondary};
  right: 0px;
  padding: 12px;
  z-index: 99;
  margin-top: -5px;
`;

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      fieldActive: false,
      password: ''
    };
    this.activateFocus = this.activateFocus.bind(this);
    this.disableFocus = this.disableFocus.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  activateFocus() {
    this.setState({
      fieldActive: true
    });
  }

  disableFocus(e) {
    if (e.target.value === '') {
      this.setState({
        fieldActive: false
      });
    }
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
    this.props.onChange(e, 'password');
    this.activateFocus(e);
    e.preventDefault();
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <label className={this.state.fieldActive ? 'field-active' : 'field'}>
          {this.props.label}
        </label>
        <Input
          type={this.state.hidden ? 'password' : 'text'}
          value={this.props.value}
          name={this.props.name}
          onFocus={this.activateFocus}
          onBlur={this.disableFocus}
          onChange={e => {
            this.handlePasswordChange(e);
            this.props.handleChange(e);
          }}
          style={{ paddingRight: 35 }}
        />
        <ShowHide
          onClick={this.toggleShow}
          className={`icon-${this.state.hidden ? 'eye' : 'hide'}`}
        />
      </div>
    );
  }
}

export default PasswordInput;
