/*eslint eqeqeq: 0*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Container, { Box } from '../styles/Container';
import { Form, FormCol } from '../styles/Form';
import Button from '../styles/Button';
import Link from '../styles/Link';
import { Title2 } from '../styles/Titles';
import { Text, TextL } from '../styles/Texts';
import { BannerImg, BannerWrapper } from '../Banner';
import { AvantageSlider } from '../Avantages';
import ScrollToTop from '../ScrollToTop';
import CountryDropdown from '../form/CountryDropdown';
import InputText from '../form/InputText';
import bgImg from '../../img/informations-complementaires.jpg';

export default function Registration({
  validated,
  handleSubmit,
  onChange2,
  goToRecap,
  goToCalendar,
  goToServices,
  onSuggestions,
  onCountryChange,
  onChange,
  ...props
}) {
  const { t } = useTranslation();
  const SignUpSchema = Yup.object().shape({
    artisteName: Yup.string()
      .min(2, t('tooShort'))
      .max(50, t('tooLong'))
      .required(t('completeField')),
    titleName: Yup.string()
      .min(2, t('tooShort'))
      .max(50, t('tooLong'))
      .required(t('completeField')),
    producer: Yup.string()
      .min(2, t('tooShort'))
      .max(50, t('tooLong'))
      .test(
        'producer',
        t('completeField'),
        () => (props['optionRealisation'] && props['producer'] != '') || !props['optionRealisation']
      ),
    producerStreet: Yup.string()
      .min(2, t('emailShort'))
      .max(80, t('emailLong'))
      .test(
        'producerStreet',
        t('completeField'),
        () =>
          (props['optionRealisation'] && props['producerStreet'] != '') ||
          !props['optionRealisation']
      ),
    producerCity: Yup.string()
      .min(2, t('tooShort'))
      .max(50, t('tooLong'))
      .test(
        'producerCity',
        t('completeField'),
        () =>
          (props['optionRealisation'] && props['producerCity'] != '') || !props['optionRealisation']
      ),
    producerCountry: Yup.string().test(
      'producerCountry',
      t('completeField'),
      () =>
        (props['optionRealisation'] && props['producerCountry'] != '') ||
        !props['optionRealisation']
    ),
    clipUrl: Yup.string().test(
      'clipUrl',
      t('clipUrl'),
      () =>
        (!props['optionRealisation'] && props['clipUrl'].length > 7) || props['optionRealisation']
    )
  });
  return (
    <>
      <ScrollToTop />
      <BannerWrapper>
        <BannerImg src={bgImg} alt="YouTrace Inscription" className="index" />
        <Container small mb={4}>
          <Box center my={5}>
            {props['optionRealisation'] && <Title2>{t('tellUs')}</Title2>}
            {props['optionDiffusion'] &&
              !props['optionPromotion'] &&
              !props['optionRealisation'] && <Title2>{t('infVideo')}</Title2>}
            {props['optionPromotion'] && !props['optionRealisation'] && (
              <Title2>{t('infPromo')}</Title2>
            )}
          </Box>
          {props['errors'] && (
            <Text error center>
              {props['errors']}
            </Text>
          )}
          <Formik
            initialValues={{
              artisteName: props['artisteName'] || '',
              titleName: props['titleName'] || '',
              clipUrl: props['clipUrl'] || '',
              producer: props['producer'] || '',
              producerStreet: props['producerStreet'] || '',
              producerCountry: props['producerCountry'] || ''
            }}
            validationSchema={SignUpSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={values => {
              props['optionRealisation'] ? goToRecap() : goToCalendar();
            }}
          >
            {({ errors, handleChange, handleBlur, handleSubmit }) => (
              <>
                <Form white>
                  <FormCol>
                    <InputText
                      name="artisteName"
                      label={t('artist') + '* :'}
                      value={props['artisteName'] || ''}
                      handleChange={e => {
                        handleChange(e);
                        onChange2(e, 'artisteName');
                      }}
                    />
                    {errors.artisteName && <Text error>{errors.artisteName}</Text>}
                  </FormCol>
                  <FormCol>
                    <InputText
                      name="titleName"
                      label={t('title') + '* :'}
                      value={props['titleName'] || ''}
                      handleChange={e => {
                        handleChange(e);
                        onChange2(e, 'titleName');
                      }}
                    />
                    {errors.titleName && <Text error>{errors.titleName}</Text>}
                  </FormCol>
                  {!props['optionRealisation'] && (
                    <FormCol>
                      <InputText
                        name="clipUrl"
                        label={t('linkVideo') + '* :'}
                        value={props['clipUrl'] || ''}
                        handleChange={e => onChange2(e, 'clipUrl')}
                      />
                      {errors.clipUrl && <Text error>{errors.clipUrl}</Text>}
                    </FormCol>
                  )}
                </Form>
                {props['optionRealisation'] ? (
                  <>
                    <TextL my={3} mt={4}>
                      {t('whoIsTheProducer')}
                    </TextL>
                    <Form white>
                      <FormCol>
                        <InputText
                          name="producer"
                          label={t('SurnameFirstName') + '* :'}
                          value={props['producer'] || ''}
                          handleChange={e => {
                            handleChange(e);
                            onChange2(e, 'producer');
                          }}
                        />
                        {errors.producer && <Text error>{errors.producer}</Text>}
                      </FormCol>
                      <FormCol>
                        <InputText
                          name="producerStreet"
                          label={t('StreetNoAndName') + '* :'}
                          value={props['producerStreet'] || ''}
                          handleChange={e => {
                            handleChange(e);
                            onChange2(e, 'producerStreet');
                          }}
                        />
                        {errors.producerStreet && <Text error>{errors.producerStreet}</Text>}
                      </FormCol>
                      <FormCol>
                        <InputText
                          name="producerCity"
                          label={t('ZipCodeAndCity') + '* :'}
                          value={props['producerCity'] || ''}
                          handleChange={e => {
                            handleChange(e);
                            onChange2(e, 'producerCity');
                          }}
                        />
                        {errors.producerCity && <Text error>{errors.producerCity}</Text>}
                      </FormCol>
                      <FormCol>
                        <CountryDropdown
                          label={t('registration.country') + '* :'}
                          placeholder={props['producerCountry'] || ''}
                          onSuggestions={onSuggestions}
                          onCountryChange={onCountryChange}
                          name="producerCountry"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            onChange2(e, 'producerCountry');
                          }}
                          value={props['producerCountry'] || ''}
                          countries={props['producerCountry']}
                        />
                        {errors.producerCountry && <Text error>{errors.producerCountry}</Text>}
                      </FormCol>
                      <FormCol>
                        <InputText
                          name="label"
                          label={t('label') + ' :'}
                          value={props['label'] || ''}
                          handleChange={e => {
                            handleChange(e);
                            onChange2(e, 'label');
                          }}
                        />
                      </FormCol>
                      <FormCol mt={3} fWidth>
                        <Button onClick={handleSubmit}>{t('next')}</Button>
                        <Box mt={3} center>
                          <Link orange onClick={goToServices}>
                            <small>{t('previous')}</small>
                          </Link>
                        </Box>
                      </FormCol>
                    </Form>
                  </>
                ) : (
                  <FormCol mt="20px!important" fWidth>
                    <Button onClick={handleSubmit}>{t('next')}</Button>
                    <Box mt={3} center>
                      <Link orange onClick={goToServices}>
                        <small>{t('previous')}</small>
                      </Link>
                    </Box>
                  </FormCol>
                )}
              </>
            )}
          </Formik>
        </Container>
      </BannerWrapper>
      <AvantageSlider />
    </>
  );
}
