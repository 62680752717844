/*eslint eqeqeq: 0*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteCalendar from 'react-infinite-calendar';

import OrderCoordinateur from '../../Components/orders/OrderCoordinateur';
import Container, { Box, Flex } from '../styles/Container';
import InputText from '../form/InputText';
import Button from '../styles/Button';
import Dropdown from '../form/Dropdown';
import { TextL } from '../styles/Texts';
import { StyledFilter } from './orders.style';
import Theme from '../../theme';
import worldCountries from '../../utils/worldCountries';
import Loader from 'react-loader-spinner';

export default function Filter({ locationFilters, orders, locale }) {
  const { t, i18n } = useTranslation();
  const [filtredOrders, setFiltredOrders] = useState(null);
  const [countriesList, setCountriesList] = useState(null);
  const [filtersActive, setFiltersActive] = useState(false);
  const [filters, setFilters] = useState(null);
  const [calendar, setCalendar] = useState({ start: false, end: false });

  const allStatus = [
    t('waitpayment'),
    t('payed'),
    t('orderdelivered'),
    t('canceled'),
    t('paymentMismatch'),
    t('initialized'),
    t('failed'),
    t('inprogress'),
    t('prodDone'),
    t('customerContacted')
  ];

  const allServices = [t('realisation'), t('diffusion'), t('promotion'), t('distribution')];

  useEffect(() => {
    if (orders) {
      let unfiltredCountriesList = [];
      orders.map(el => unfiltredCountriesList.push(el.country.code));
      setCountriesList([...new Set(unfiltredCountriesList)]);
    }
    filter(orders);
  }, []);

  useEffect(() => {
    if (locationFilters) {
      setFilters({
        ...filters,
        status: { code: locationFilters.status, name: allStatus[locationFilters.status] },
        country: { code: locationFilters.country?.code, name: locationFilters.country?.name },
        startDate: { code: locationFilters.date?.code, name: locationFilters.date?.name },
        endDate: { code: null, name: null },
        services: { code: null, name: null },
        name: { code: null, name: null }
      });
    }
  }, [locationFilters]);

  useEffect(() => {
    filter(orders);
  }, [filters, orders]);

  function handleDate(field, date) {
    setFilters({
      ...filters,
      [field]: { name: date }
    });
    setCalendar({ ...calendar, start: false });
  }
  function onChange(e, field) {
    setFilters({
      ...filters,
      [field]: { name: e.target.value }
    });
  }

  function alphaToCountryObj(alpha) {
    return Object.values(worldCountries['fr']).filter(el => el.alpha_2 === alpha)[0];
  }

  function onDropdownChange(name, field) {
    setFilters({
      ...filters,
      [field]: {
        code: name.target.getAttribute('code'),
        name:
          alphaToCountryObj(name.target.getAttribute('values')?.toString()) ||
          name.target.getAttribute('values')
      }
    });
  }

  function convertDateToTimestamp(date) {
    let timestamp = date.split('/');
    timestamp.filter(item => item !== timestamp[1]);
    timestamp.unshift(timestamp[1]);
    timestamp.splice(2, 1);
    timestamp = timestamp.join('/');
    return new Date(timestamp);
  }

  function filter(data) {
    setFiltredOrders(null);
    if (filters?.name?.name) {
      data = data.filter(o => o.artist.toLowerCase().includes(filters.name.name.toLowerCase()));
    }
    if (filters?.status) {
      data = data.filter(o => o.status == filters.status.code);
    }
    if (filters?.service?.code) {
      if (filters?.service?.code === '0') {
        data = data.filter(
          o => o.orderServices.find(os => os.serviceType.startsWith('PACK')) != null
        );
      }
      if (filters?.service?.code === '1') {
        data = data.filter(o => o.orderServices.find(os => os.serviceType === 'UPLOAD') != null);
      }
      if (filters?.service?.code === '2') {
        data = data.filter(
          o => o.orderServices.find(os => os.serviceType.startsWith('PROMO')) != null
        );
      }
      if (filters?.service?.code === '3') {
        data = data.filter(
          o => o.orderServices.find(os => os.serviceType === 'DISTRIBUTION') != null
        );
      }
    }
    if (filters?.startDate?.name) {
      data = data.filter(el => {
        const d1 = convertDateToTimestamp(el.createdAt).getTime();
        const d2 = new Date(filters?.startDate?.name).getTime();
        return d1 >= d2;
      });
    }
    if (filters?.endDate?.name) {
      data = data.filter(el => {
        const d1 = convertDateToTimestamp(el.createdAt).getTime();
        const d2 = new Date(filters?.startDate?.name).getTime();
        return d1 >= d2;
      });
    }
    if (filters?.country?.name) {
      data = data.filter(o => o.country.code === filters?.country.name.alpha_2);
    }
    setFiltredOrders(data);
  }

  return (
    <div>
      <StyledFilter mb={5} py={3} center>
        <Container small>
          <Box>
            <Flex className={filtersActive ? 'filters show' : 'filters'} flexDirection="column">
              <Box my={2}>
                <Dropdown
                  label={allStatus[filters?.status?.code] || t('status')}
                  options={allStatus.map(el => el)}
                  handleClick={name => {
                    onDropdownChange(name, 'status');
                  }}
                  name="name"
                />
              </Box>
              <Box mb={2}>
                <Dropdown
                  label={allServices[filters?.service?.code] || t('services')}
                  options={allServices.map(el => el)}
                  handleClick={name => {
                    onDropdownChange(name, 'service');
                  }}
                />
              </Box>
              <Box mb={2}>
                <Dropdown
                  label={filters?.country?.name?.name || t('billedCountry')}
                  values={countriesList}
                  options={countriesList?.map((country, i) => {
                    const translatedCountries = Object.values(
                      worldCountries[i18n.language.slice(0, 2)]
                    ).find(el => el.alpha_2 === country);
                    return translatedCountries.name;
                  })}
                  handleClick={name => {
                    onDropdownChange(name, 'country');
                  }}
                />
              </Box>
              <InputText
                value={filters?.name?.name || ''}
                handleChange={e => {
                  onChange(e, 'name');
                }}
                label={t('artist') + ' :'}
                name="name"
              />
              <Box onClick={() => setCalendar({ ...calendar, start: true })}>
                <InputText
                  value={filters?.startDate?.name || ''}
                  label={`${t('startDate')} :`}
                  name="start"
                />
              </Box>
              <Box fWidth mb={2}>
                {calendar.start && (
                  <InfiniteCalendar
                    theme={{
                      selectionColor: Theme.dark.colors.orange,
                      weekdayColor: Theme.dark.colors.orange,
                      headerColor: Theme.dark.colors.orange,
                      floatingNav: {
                        background: Theme.dark.colors.orange
                      }
                    }}
                    width={'100%'}
                    height={300}
                    locale={{
                      locale: locale,
                      headerFormat: 'dddd D MMM'
                    }}
                    selected={filters?.startDate?.name}
                    onSelect={date => handleDate('startDate', date)}
                  />
                )}
              </Box>
              <Box onClick={() => setCalendar({ ...calendar, end: true })}>
                <InputText
                  value={filters?.endDate?.name || ''}
                  label={t('endDate') + ' :'}
                  name="end"
                />
              </Box>
              <Box>
                {calendar.end && (
                  <InfiniteCalendar
                    theme={{
                      selectionColor: Theme.dark.colors.orange,
                      weekdayColor: Theme.dark.colors.orange,
                      headerColor: Theme.dark.colors.orange,
                      floatingNav: {
                        background: Theme.dark.colors.orange
                      }
                    }}
                    width={'100%'}
                    height={300}
                    locale={{
                      locale: locale,
                      headerFormat: 'dddd D MMM'
                    }}
                    selected={filters?.endDate?.name}
                    onSelect={date => handleDate('endDate', date)}
                  />
                )}
              </Box>
            </Flex>
          </Box>
          <Box mt={3}>
            {!filtersActive ? (
              <Button onClick={() => setFiltersActive(true)}>{t('filter')}</Button>
            ) : (
              <TextL
                orange
                onClick={() => {
                  setFiltersActive(false);
                  setFilters({
                    status: { code: null, name: null },
                    country: { code: null, name: null },
                    startDate: { code: null, name: null },
                    endDate: { code: null, name: null },
                    services: { code: null, name: null },
                    name: { code: null, name: null }
                  });
                }}
                style={{ cursor: 'pointer', display: 'inline-block' }}
              >
                {t('resetFilters')}
              </TextL>
            )}
          </Box>
        </Container>
      </StyledFilter>
      <Container>
        <Box px={[0, 0, 6]} pb={2}>
          {filtredOrders ? (
            filtredOrders.map((orderData, i) => <OrderCoordinateur key={i} {...orderData} />)
          ) : (
            <Loader
              type="TailSpin"
              color="#fff"
              height={25}
              width={25}
              style={{ textAlign: 'center', margin: '5rem' }}
            />
          )}
        </Box>
      </Container>
    </div>
  );
}
