import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box } from '../styles/Container.js';
import worldCountries from '../../utils/worldCountries';

export default function CountriesAvaible({ countries, country, onChangeCountry, countryLabel }) {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [language, setLanguage] = useState('');

  const { i18n } = useTranslation();

  useEffect(() => {
    setLanguage(i18n.language.slice(0, 2));
  }, [i18n.language]);

  function showDropdownMenu() {
    setLanguage(i18n.language.slice(0, 2));
    setDisplayMenu(true);
    document.addEventListener('click', hideDropdownMenu);
  }

  function changeCountry(name) {
    let country = countries.find(c => c.country === name);
    setLanguage(i18n.language.slice(0, 2));
    onChangeCountry(country);
  }

  function hideDropdownMenu() {
    setDisplayMenu(false);
    document.removeEventListener('click', hideDropdownMenu);
  }

  function translateActiveCountry() {
    return (
      language &&
      Object.values(worldCountries[language]).find(el => el.alpha_2 === country.code).name
    );
  }

  return (
    <Box>
      <Select onClick={() => showDropdownMenu()}>
        {country === '' || country === undefined ? countryLabel + '*' : translateActiveCountry()}
        <span>{displayMenu ? '▲' : '▼'}</span>
      </Select>
      {displayMenu ? (
        <List>
          {countries.map((country, i) => {
            const translatedCountries = Object.values(worldCountries[language]).find(
              el => el.alpha_2 === country.code
            );
            return (
              <li key={i} onClick={() => changeCountry(country.country)}>
                {translatedCountries.name}
              </li>
            );
          })}
        </List>
      ) : null}
    </Box>
  );
}

const Select = styled(Box)`
  padding: 10px;
  outline: none;
  font-size: 16px;
  width: 100%;
  color: ${props => props.theme.colors.secondary};
  font-family: ${props => props.theme.fonts.inter};
  box-sizing: border-box;
  transition: 0.18s ${props => props.theme.transitions.easeInCubic};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 0;
  border: 1px solid;
  color: ${props => props.theme.colors.secondary};
  background: ${props => props.theme.colors.primary};
`;

const List = styled.ul`
  border: 1px solid;
  font-size: 14px;
  color: ${props => props.theme.colors.secondary};
  background: ${props => props.theme.colors.primary};
  li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid;
    &::before {
      content: none;
    }
  }
`;
