import React from 'react';
import uuid from 'uuid';

import { useTranslation } from 'react-i18next';
import Plans from './Plans';
import { Box } from '../styles/Container';
import { Input, Switcher, SwitchItem } from '../styles/Form';
import { Text, TextL, TextS } from '../styles/Texts';
import bgUrl from '../../img/option-clip.png';
import CountriesAvaible from '../form/CountriesAvaible';

export default function Options({ onChange, isMobile, onChangeCountry, onSuggestions, ...props }) {
  function handleCheckboxes(checked, field) {
    const e = {
      target: {
        type: 'checkbox',
        checked
      }
    };

    onChange(e, field);
  }

  const checked = props['optionRealisation'] || false;
  const { t } = useTranslation();

  return (
    <div className={checked ? 'checked' : ''}>
      <SwitchItem
        tShadow
        key={uuid()}
        style={{ backgroundImage: `url(${bgUrl || ''})` }}
        onClick={() => handleCheckboxes(!checked, 'optionRealisation')}
        className={checked && 'checked'}
      >
        <Box className="overlay" />
        <TextL>{t('makeMyVideo.title')}</TextL>
        <Switcher>
          <Input
            onChange={() => handleCheckboxes(!checked, 'optionRealisation')}
            type="checkbox"
            id="optionRealisation"
            name="radio"
            checked={checked}
          />
          <label htmlFor="optionRealisation">
            <span></span>
          </label>
        </Switcher>
        <Box fWidth pt={3}>
          <Text>{t('makeMyVideo.resume')}</Text>
          <TextS mt={2} orange>
            {t('makeMyVideo.inf')}
          </TextS>
        </Box>
      </SwitchItem>

      {props['optionRealisation'] && (
        <Box
          mb={4}
          center
          style={{
            zIndex: '9',
            position: 'relative'
          }}
        >
          <Text mb={3}>{t('makeMyVideo.country')}</Text>
          <CountriesAvaible
            onChangeCountry={onChangeCountry}
            countryLabel={t('registration.country')}
            country={
              props.selectedCountry && props.selectedCountry.productionAvailable
                ? props.selectedCountry
                : ''
            }
            countries={props.countries.filter(c => c.productionAvailable)}
          />
          {props['errors'] && (
            <Text error center>
              {props['errors']}
            </Text>
          )}
        </Box>
      )}

      {props['selectedCountry'] &&
        props['selectedCountry'].productionAvailable &&
        props['optionRealisation'] && <Plans onChange={onChange} {...props} />}
    </div>
  );
}
