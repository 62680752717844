import styled, { css } from 'styled-components';
import { space } from 'styled-system';

const Button = styled.a`
	display: inline-block;
	position: relative;
	padding: 0 30px;
	height: 50px;
	line-height: 50px;
	border: none;
	box-sizing: border-box;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
	cursor: pointer;
	background: ${props => props.theme.colors.orange};
	color: white;
	outline: none;
	white-space: nowrap;
	transition: all 250ms ${props => props.theme.transitions.easeInOutCubic};
	text-decoration: none;
	min-width: 200px;
	${space}
	&:hover {
		background: ${props => props.theme.colors.orange2};
	}
	${props =>
    props.transparent &&
    css`
      background: none;
      border: 1px solid ${props => props.theme.colors.grey};
      color: ${props => props.theme.colors.orange};
      &:hover {
        background: ${props => props.theme.colors.grey};
        color: white;
      }
    `}
	${props =>
    props.white &&
    css`
      background: none;
      border: 1px solid ${props => props.theme.colors.grey};
      color: ${props => props.theme.colors.orange};
      &:hover {
        background: ${props => props.theme.colors.grey};
        color: white;
      }
    `}
	${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
	${props =>
    props.small &&
    css`
      padding: 10px;
      min-width: 0px;
      height: 27px;
      line-height: 6px;
      background: ${props => props.theme.colors.green};
      font-size: 12px;
      &:hover {
        background: #006900;
      }
    `}
`;

export default Button;
