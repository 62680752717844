import React from 'react';
import styled from 'styled-components';

import { TextL } from '../styles/Texts';
import Box, { Flex } from '../styles/Container';

const Wrapper = styled.a`
  color: white;
  text-decoration: none;
  min-width: 100%;
  margin: 16px 0;
  display: inline-block;
  &:hover {
    .overlay {
      opacity: 0.8;
    }
    i {
      margin-right: -5px;
    }
  }

  .imgWrapper {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  i {
    margin-left: 10px;
    margin-top: 4px;
    transition: 0.4s;
  }

  .overlay {
    opacity: 0.6;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 0;
    background: rgb(0, 0, 0);
    transition: all 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  }

  .content {
    z-index: 9;
    position: relative;
  }
`;

function Link({ href, icon, title }) {
  return (
    <Wrapper href={href}>
      <Flex justifyContent="space-between" alignItems="center">
        <Box
          className="imgWrapper"
          style={{
            backgroundImage: 'url(' + icon + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        >
          <Box className="overlay"/>
          <Flex justifyContent="space-between" my={3} px={3} className="content">
            <TextL>{title}</TextL>
            <i className="icon-chevron-right"/>
          </Flex>
        </Box>
      </Flex>
    </Wrapper>
  );
}

export default Link;
