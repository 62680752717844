import trailerVideo from '../../img/diffusion.mp4';
import imgDiffuser from '../../img/diffusion-youtube.png';
import imgPromo from '../../img/trace-social.png';
import imgRealiser from '../../img/maitre-gims.png';
import imgDistribution from '../../img/distribution.png';

const fr = {
  video: trailerVideo,
  title: 'Diffuser mon clip',
  subtitle:
    '<span>Diffuse ton clip sur YouTrace</span> et<br/> fais-le découvrir à un public de<br/> passionnés',
  callToAction: 'Commencer',
  introduction: {
    img: imgDiffuser,
    title: 'Comment diffuser mon clip sur <span>YouTrace</span>',
    paragraph:
      'Si tu as déjà un clip que tu veux faire connaître, tu peux le diffuser directement sur notre chaine Youtube et notre site YouTrace.tv, à la date de ton choix. Nous proposons des playlists personnalisées par pays pour une plus grande visibilité à l’international. <br/><br/> <i>*La diffusion de ta vidéo est gratuite si tu réalises ton clip avec YouTrace.</i>'
  },
  services: {
    title: 'Les autres services YouTrace',
    items: [
      {
        title: 'Réaliser mon clip',
        cta: 'Découvrir',
        link: '/services/realisation',
        img: imgRealiser
      },
      {
        title: 'Faire ma promo digitale',
        cta: 'Découvrir',
        link: '/services/promotion',
        img: imgPromo
      },
      {
        title: 'Distribuer ma musique',
        cta: 'Découvrir',
        link: '/services/distribution',
        img: imgDistribution
      }
    ]
  }
};
const en = {
  video: trailerVideo,
  title: 'Feature My Video',
  subtitle:
    '<span>Get your video featured</span> on YouTrace<br/> to attract an enthusiastic audience',
  callToAction: 'Get started',
  introduction: {
    img: imgDiffuser,
    title: 'How to Get my Video Featured on <span>YouTrace</span>',
    paragraph:
      'If you already have a video, you can share it directly on our YouTube channel and YouTrace.tv website. Just select the \'Feature my Video\' package, fill out the form and pick the date that fits you and get your video on our platforms.'
  },
  services: {
    title: 'Other YouTrace services',
    items: [
      {
        title: 'Produce my Video',
        cta: 'Discover',
        link: '/services/realisation',
        img: imgRealiser
      },
      {
        title: 'Create a Campaign for Me',
        cta: 'Discover',
        link: '/services/promotion',
        img: imgPromo
      },
      {
        title: 'Distribute my music',
        cta: 'Discover',
        link: '/services/distribution',
        img: imgDistribution
      }
    ]
  }
};
const pt = {
  video: trailerVideo,
  title: 'Apresente Meu Video',
  subtitle: '<span>Veicule seu clipe no YouTrace </span><br/> e alcance seu público de fãs',
  callToAction: 'Começar',
  introduction: {
    img: imgDiffuser,
    title: 'Como fazer meu vídeo ser apresentado no <span>YouTrace</span>',
    paragraph:
      'Se você já tem um videoclipe, pode compartilhá-lo diretamente em nosso canal no YouTube e no site YouTrace.tv.<br/> <br/> Basta escolher a data que se adapta a você e colocar seu vídeo em nossa lista de reprodução para visibilidade imediata.'
  },
  services: {
    title: 'Outros Serviços da YouTrace',
    items: [
      {
        title: 'Crie meu vídeo',
        cta: 'Descobrir',
        link: '/services/realisation',
        img: imgRealiser
      },
      {
        title: 'Faça minha promoção digital',
        cta: 'Descobrir',
        link: '/services/promotion',
        img: imgPromo
      },
      {
        title: 'Distribuir minha música',
        cta: 'Descobrir',
        link: '/services/distribution',
        img: imgDistribution
      }
    ]
  }
};

export { fr, en, pt };
