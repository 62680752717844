import trailerVideo from '../../img/promotion.mp4';
import imgDiffuser from '../../img/diffusion-youtube.png';
import imgPromo from '../../img/trace-social.png';
import imgRealiser from '../../img/maitre-gims.png';
import imgDistribution from '../../img/distribution.png';

const fr = {
  video: trailerVideo,
  title: 'Faire ma promo digitale',
  subtitle:
    'Fais-toi remarquer sur <span>les réseaux<br/> de YouTrace</span> partout dans le monde',
  callToAction: 'Commencer',
  introduction: {
    img: imgPromo,
    title: 'Comment faire ma <span>promo digitale</span>',
    paragraph:
      'Bénéficie de la puissance de YouTrace et fais ta promo digitale sur nos réseaux sociaux à travers des posts, stories ou articles.'
  },
  services: {
    title: 'Les autres services YouTrace',
    items: [
      {
        title: 'Réaliser mon clip',
        cta: 'Découvrir',
        link: '/services/realisation',
        img: imgRealiser
      },
      {
        title: 'Diffuser mon clip',
        cta: 'Découvrir',
        link: '/services/diffusion',
        img: imgDiffuser
      },
      {
        title: 'Distribuer ma musique',
        cta: 'Découvrir',
        link: '/services/distribution',
        img: imgDistribution
      }
    ]
  }
};
const en = {
  video: trailerVideo,
  title: 'Organize my digital campaign',
  subtitle: 'Stand out on <span>YouTrace social media</span> <br/> all over the world',
  callToAction: 'Get started',
  introduction: {
    img: imgPromo,
    title: 'How can I create my <span>digital campaign</span>',
    paragraph:
      'Use the power of YouTrace and promote your video on our various social media platforms and benefit from the reach and reputation of Trace.'
  },
  services: {
    title: 'Other YouTrace services',
    items: [
      {
        title: 'Produce my Video',
        cta: 'Discover',
        link: '/services/realisation',
        img: imgRealiser
      },
      {
        title: 'Feature my Video',
        cta: 'Discover',
        link: '/services/diffusion',
        img: imgDiffuser
      },
      {
        title: 'Distribute my music',
        cta: 'Discover',
        link: '/services/distribution',
        img: imgDistribution
      }
    ]
  }
};
const pt = {
  video: trailerVideo,
  title: 'Campanha de Mídia Social',
  subtitle: 'Destaque-se nas <span>redes sociais</span> YouTrace em todo o mundo.',
  callToAction: 'Começar',
  introduction: {
    img: imgPromo,
    title: 'Como ter uma campanha criada no <span>YouTrace</span>',
    paragraph:
      'Use o poder do YouTrace e promova seu vídeo em nossas várias redes sociais. Você pode compartilhar seu conteúdo com postagens, histórias e artigos.'
  },
  services: {
    title: 'Outros Serviços da YouTrace',
    items: [
      {
        title: 'Crie meu vídeo',
        cta: 'Descobrir',
        link: '/services/realisation',
        img: imgRealiser
      },
      {
        title: 'Transmitir meu vídeo',
        cta: 'Descobrir',
        link: '/services/diffusion',
        img: imgDiffuser
      },
      {
        title: 'Distribuir minha música',
        cta: 'Descobrir',
        link: '/services/distribution',
        img: imgDistribution
      }
    ]
  }
};

export { fr, en, pt };
