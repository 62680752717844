import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import Container, { Box } from '../Components/styles/Container';
import { Title2, Title5 } from '../Components/styles/Titles';
import Order from '../Components/orders/Order';
import Button from '../Components/styles/Button';
import Filter from '../Components/orders/Filter';
import Link from '../Components/styles/Link';
import { userContext } from '../context/userContext';
import api from '../client/api';
import { useLocation } from 'react-router';

export default function Orders(props) {
  const [orders, setOrders] = useState(null);
  const { t, i18n } = useTranslation();
  let locale = i18n.language.slice(0, 2);
  let location = useLocation();
  const filtersFromLocation = location.state?.filters;

  useEffect(() => {
    api.fetchClips(orders => {
      setOrders(orders);
    });
  }, []);

  if (!orders) {
    return (
      <Loader
        type="TailSpin"
        color="#fff"
        height={25}
        width={25}
        style={{ textAlign: 'center', margin: '5rem' }}
      />
    );
  } else {
    return (
      <Box py={[2, 3]} pb={4}>
        <Container>
          <Box p={4} center>
            <Title2 bold>{t('orderSymmary')}</Title2>
          </Box>
        </Container>
        {orders.length === 0 ? (
          <Container>
            <Box center>
              <userContext.Consumer>
                {({ user }) => {
                  if (user && user.idRole !== '1') {
                    return <Title5>Tu n'as pas encore reçu de commande</Title5>;
                  } else {
                    return (
                      <>
                        <Title5>Tu n'as pas encore passé de commande</Title5>
                        <Button mt={4} href="/services">
                          Voir les services
                        </Button>
                      </>
                    );
                  }
                }}
              </userContext.Consumer>
            </Box>
          </Container>
        ) : (
          <userContext.Consumer>
            {({ user }) => {
              if (user && user.idRole !== '1') {
                return (
                  <Filter locationFilters={filtersFromLocation} locale={locale} orders={orders} />
                );
              } else if (user && user.idRole === '1') {
                return (
                  <Container>
                    <Box px={[0, 0, 6]} pb={2} style={{ overflow: 'hidden' }}>
                      {orders.map((orderData, i) => (
                        <Order key={i} {...orderData} />
                      ))}
                    </Box>
                  </Container>
                );
              }
            }}
          </userContext.Consumer>
        )}
        <userContext.Consumer>
          {({ user }) => {
            if (user && user.idRole !== '1') {
              return (
                <Container>
                  <Box my={4} center>
                    <Link orange href="/dashboard">
                      Dashboard
                    </Link>
                  </Box>
                </Container>
              );
            }
          }}
        </userContext.Consumer>
      </Box>
    );
  }
}
