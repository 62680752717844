import React from 'react';
import styled from 'styled-components';

export const StyledBurger = styled.button`
  ${({ open }) => (open ? 'position: fixed' : 'position: absolute')};
  top: 14px;
  right: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.2rem;
  height: 1.2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  z-index: 99999;

  @media ${props => props.theme.mediaQueries.minSmallWidth} {
    display: none;
  }

  &:focus {
    outline: none;
  }

  div {
    width: 1.2rem;
    height: 3px;
    background: #bfbfbf;
    border-radius: 10px;
    transition: 0.6s ${props => props.theme.transitions.easeInOutCubic};
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div/>
      <div/>
      <div/>
    </StyledBurger>
  );
};
export default Burger;
