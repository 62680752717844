import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { BannerWrapper } from '../Components/Banner';
import Button from '../Components/styles/Button';
import Container, { Box, Flex } from '../Components/styles/Container';
import VideoWrapper, { Filter } from '../Components/styles/VideoWrapper';
import { Title1, Title2, Title3, Title5 } from '../Components/styles/Titles';
import { Text } from '../Components/styles/Texts';
import Steps from '../Components/steps/Steps';
import Artists from '../Components/artists/Artists';
import OtherServices from '../Components/otherServices/OtherServices';
import PaymentSolutions from '../Components/PaymentSolutions';

export default function Realisation() {
  let { id } = useParams();
  let { en, pt, fr } = require('../lang/services/' + id + '.js');
  const { i18n } = useTranslation();
  const langs = {
    fr: fr,
    en: en,
    pt: pt
  };
  let lang = i18n.language.slice(0, 2);
  const content = langs[lang];
  return (
    <Box>
      <BannerWrapper>
        <VideoWrapper
          dangerouslySetInnerHTML={{
            __html: `
              <video playsInline autoPlay muted loop>
                <source src="${content.video}" type='video/mp4' />
              onChange2</video>
            `
          }}
        />
        <Filter />
        <Box style={{ position: 'relative' }} py={5}>
          <Title1>{content.title}</Title1>
          <Box mb={4}>
            <Title2 light dangerouslySetInnerHTML={{ __html: content.subtitle }} />
          </Box>
          <Button href={localStorage.getItem('access_token') ? '/services' : '/inscription'}>
            {content.callToAction}
          </Button>
        </Box>
      </BannerWrapper>
      <Container py={[3, 4]}>
        <Flex alignItems="center">
          <Box width="50%" style={{ minWidth: 500 }}>
            <Title3 dangerouslySetInnerHTML={{ __html: content.introduction.title }} />
            <Text dangerouslySetInnerHTML={{ __html: content.introduction.paragraph }} />
          </Box>
          <Box width="50%" style={{ minWidth: 500 }}>
            <Box mx={5}>
              <img style={{ maxWidth: '100%' }} src={content.introduction.img} alt="" />
            </Box>
          </Box>
        </Flex>
      </Container>
      {content.steps && (
        <Container py={[3, 4]}>
          <Box center>
            <Title3 dangerouslySetInnerHTML={{ __html: content.steps.title }} />
          </Box>
          <Steps itemsList={content.steps.step} />
          <Box mt={4} center>
            <Button href={localStorage.getItem('access_token') ? '/services' : '/inscription'}>
              {content.callToAction}
            </Button>
          </Box>
        </Container>
      )}
      {content.artists && (
        <Container py={[3, 4]}>
          <Box center>
            <Title3 dangerouslySetInnerHTML={{ __html: content.artists.title }} />
            <Title5 dangerouslySetInnerHTML={{ __html: content.artists.subtitle }} />
          </Box>
          <Artists />
        </Container>
      )}
      <Container py={[3, 4]} mb={[3, 4]}>
        <Box center>
          <Title3 dangerouslySetInnerHTML={{ __html: content.services.title }} />
        </Box>
        <OtherServices itemsList={content.services.items} />
      </Container>
      <PaymentSolutions />
    </Box>
  );
}
