import React, { Component } from 'react';

import { Title2, Title4 } from '../../Components/styles/Titles';
import Container, { Box } from '../../Components/styles/Container';
import Button from '../../Components/styles/Button';
import Link from '../../Components/styles/Link';
import CountriesAvaible from '../../Components/form/CountriesAvaible';
import StackedBar from '../../Components/dashboard/graphics/StackedBar';
import api from '../../client/api';
import { userContext } from '../../context/userContext';
import { withTranslation } from 'react-i18next';
import worldCountries from '../../utils/worldCountries';
import { activeLang } from '../../utils/translate';

class ChiffreDaffaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caDataSuperCoordinateur: [],
      countries: []
    };
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  componentDidMount() {
    api.fetchCountries(countries => {
      this.setState({ countries: countries });
    });

    api.overview(data => {
      this.setState({
        caDataSuperCoordinateur: data.sellsPerCountry
      });
    });
  }

  handleCountryChange(country) {
    window.location = '/dashboard/vue-d-ensemble/' + country.country;
  }

  render() {
    const translatedCountries = this.state.countries.map(country => {
      return Object.values(worldCountries[activeLang]).find(el => el.alpha_2 === country.code);
    });
    const countriesList = translatedCountries.map(country => country.name);
    return (
      <userContext.Consumer>
        {({ user }) => {
          if (user) {
            return (
              <>
                <Container>
                  <Box pt={4} center>
                    <Title2>
                      <span>Overview</span>
                    </Title2>
                  </Box>
                  <Box mb={4} center>
                    <Title4 bold>{this.props.t('bo.sellcountry')}</Title4>
                    <StackedBar
                      chartData={this.state.caDataSuperCoordinateur}
                      chartCategories={countriesList}
                      user={user.idRole}
                    />
                  </Box>
                </Container>

                {user.idRole === '4' && (
                  <Container small>
                    <Box center>
                      <Title4 bold>{this.props.t('bo.informationcountry')}</Title4>
                    </Box>
                    <CountriesAvaible
                      onChangeCountry={this.handleCountryChange}
                      countryLabel={this.state.selectedCountry ? this.state.selectedCountry : ''}
                      countries={this.state.countries}
                    />
                  </Container>
                )}

                <Container>
                  <Box my={4} center>
                    <Box>
                      <Button fullWidth mb={4} href="/dashboard/chiffre-mois">
                        {this.props.t('bo.ca')}
                      </Button>
                    </Box>
                    <Link orange fullWidth href="/dashboard">
                      Dashboard
                    </Link>
                  </Box>
                </Container>
              </>
            );
          }
        }}
      </userContext.Consumer>
    );
  }
}

export default withTranslation()(ChiffreDaffaire);
