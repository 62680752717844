import styled, { css } from 'styled-components';
import { space } from 'styled-system';

const Text = styled.p`
	font-size: 16px;
	line-height: 118%;
	${space}
	&.opacity {
		opacity: 0.6;
  }

  a {
    color: inherit;
  }

	${props =>
    props.opacity &&
    css`
      opacity: 0.6;
    `}
	span {
		color: ${props => props.theme.colors.orange};
	}
	${props =>
    props.orange &&
    css`
      color: ${props => props.theme.colors.orange};
      a {
        color: ${props => props.theme.colors.orange};
      }
    `}
	
	${props =>
    props.green &&
    css`
      color: ${props => props.theme.colors.green};
    `}
	${props =>
    props.grey &&
    css`
      color: ${props => props.theme.colors.grey};
      a {
        color: ${props => props.theme.colors.grey};
      }
    `}
	${props =>
    props.white &&
    css`
      color: ${props => props.theme.colors.secondary};
      a {
        color: ${props => props.theme.colors.secondary};
      }
    `}
	${props =>
    props.bold &&
    css`
      font-weight: 600;
    `}
	
	${props =>
    props.center &&
    css`
      text-align: center;
    `}
	${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}
	${props =>
    props.error &&
    css`
      background-color: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.primary};
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 10px;
      margin-top: 2px;
      padding: 8px;
    `}
	${props =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
	.orange {
		color: ${props => props.theme.colors.orange};
	}

`;
const TextL = styled(Text)`
  font-size: 18px;
  letter-spacing: -0.5px;
  font-weight: 600;
  ${space}
  span {
    color: ${props => props.theme.colors.orange};
  }
`;
const TextS = styled(Text)`
  font-size: 14px;
  letter-spacing: -0.39px;
  ${space}
`;
const TextXS = styled(Text)`
  font-size: 12px;
  letter-spacing: -0.1px;
  ${space}
`;
const TextXXS = styled(Text)`
  font-size: 12px;
  text-transform: uppercase;
  ${space}
`;

export { Text, TextL, TextS, TextXS, TextXXS };
