import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { translateServicesName } from '../../../utils/translate';
import { useDarkMode } from '../../hooks/useDarkMode';

const SimpleLine = props => {
  const { t } = useTranslation();
  const [theme] = useDarkMode();
  const options = {
    chart: {
      type: 'line',
      backgroundColor: theme === 'light' ? '#fff' : '#000',
      style: {
        fontFamily: '"Inter",Sans-Serif',
        fontWeight: 'bold'
      }
    },
    title: {
      text: ''
    },
    series: translateServicesName(props.chartData),
    xAxis: {
      categories: [
        t('jan'),
        t('feb'),
        t('mar'),
        t('apr'),
        t('may'),
        t('jun'),
        t('jul'),
        t('aug'),
        t('sep'),
        t('oct'),
        t('nov'),
        t('dec')
      ],
      title: {
        text: t('date') || '',
        style: {
          color: theme === 'light' ? '#000' : '#fff'
        }
      }
    },
    yAxis: {
      title: {
        text: t('bo.thousands') || '',
        style: {
          color: theme === 'light' ? '#000' : '#fff'
        }
      }
    },
    legend: {
      itemStyle: {
        color: theme === 'light' ? '#000' : '#fff'
      }
    },
    credits: {
      enabled: false
    }
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SimpleLine;
