import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { userContext } from './context/userContext';
import Index from './Pages/Index';
import Login from './Pages/Login';
import Registration from './Pages/Registration';
import Confirmation from './Pages/Confirmation';
import Deleted from './Components/account/Deleted';
import Subscription from './Pages/Subscription';
import Services from './Pages/Services';
import Dashboard from './Pages/dashboard/Dashboard';
import ChiffreDaffaire from './Pages/dashboard/ChiffreDaffaire';
import ChiffreDaffaireService from './Pages/dashboard/ChiffreDaffaireService';
import Ventes from './Pages/dashboard/Ventes';
import Overview from './Pages/dashboard/Overview';
import OrdersStatus from './Pages/dashboard/OrdersStatus';
import CountryStats from './Pages/CountryStats';
import Account from './Pages/Account';
import Password from './Pages/Password';
import Orders from './Pages/Orders';
import AddClip from './Pages/AddClip';
import OrderDetails from './Pages/OrderDetails';
import MentionsLegales from './Pages/Legals';
import Faq from './Components/Faq';
import Sso from './Pages/Sso';
import NotFound from './Pages/NotFound';
import InTouch from './Pages/InTouch';

const userLogged = localStorage.getItem('access_token');

const ROUTES = [
  { path: '/', key: 'ROOT', exact: true, component: () => <Index /> },
  {
    path: '/connexion',
    key: 'LOGIN',
    exact: true,
    component: () => {
      if (userLogged) {
        return <Redirect to={'/'} />;
      } else {
        return <Login />;
      }
    }
  },
  {
    path: '/inscription',
    key: 'REGISTER',
    exact: true,
    component: () => {
      if (userLogged) {
        return <Redirect to={'/'} />;
      } else {
        return <Registration />;
      }
    }
  },
  {
    path: '/mon-compte',
    key: 'ACCOUNT',
    exact: true,
    component: () => {
      if (userLogged) {
        return <Account />;
      } else {
        return <Redirect to={'/'} />;
      }
    }
  },
  {
    path: '/password/reset/:token',
    key: 'PASSWORD',
    exact: true,
    component: props => <Password {...props} />
  },
  { path: '/guest/:token', key: 'GUEST', exact: true, component: props => <Index {...props} /> },
  {
    path: '/commandes',
    key: 'ORDERS',
    exact: true,
    component: () => {
      if (userLogged) {
        return <Orders />;
      } else {
        return <Redirect to={'/'} />;
      }
    }
  },
  {
    path: '/commandes/details/:id',
    key: 'ORDER_DETAILS',
    exact: true,
    component: props => <OrderDetails {...props} />
  },
  { path: '/sso', key: 'SSO', exact: true, component: () => <Sso /> },
  { path: '/confirmation', key: 'CONFIRMATION', exact: true, component: () => <Confirmation /> },
  { path: '/compte-supprime', key: 'DELETED_USER', exact: true, component: () => <Deleted /> },
  {
    path: '/services',
    key: 'SERVICES',
    exact: true,
    component: () => {
      if (userLogged) {
        return <Subscription />;
      } else {
        return <Redirect to={'/connexion'} />;
      }
    }
  },
  {
    path: '/services/:id',
    key: 'SERVICES_LANDING',
    exact: true,
    component: props => <Services {...props} />
  },
  { path: '/foire-aux-questions', key: 'FAQ', exact: true, component: () => <Faq /> },
  { path: '/mentions-legales', key: 'LEGALS', exact: true, component: () => <MentionsLegales /> },
  {
    path: '/ajouter-clip/:id',
    key: 'ADD_CLIP',
    exact: true,
    component: () => {
      if (userLogged) {
        return <AddClip />;
      } else {
        return <Redirect to={'/connexion'} />;
      }
    }
  },
  {
    path: '/touchpay/failed*',
    key: 'TOUCH',
    exact: true,
    component: props => (
      <userContext.Consumer>
        {({ user }) => {
          return <InTouch user={user} status={'failed'} />;
        }}
      </userContext.Consumer>
    )
  },
  {
    path: '/touchpay/success*',
    key: 'TOUCH',
    exact: true,
    component: props => (
      <userContext.Consumer>
        {({ user }) => {
          return <InTouch user={user} status={'success'} />;
        }}
      </userContext.Consumer>
    )
  },
  {
    path: '/touchpay/async*',
    key: 'TOUCH',
    exact: true,
    component: props => (
      <userContext.Consumer>
        {({ user }) => {
          return <InTouch user={user} />;
        }}
      </userContext.Consumer>
    )
  },
  {
    path: '/dashboard',
    key: 'DASHBOARD',
    component: props => {
      if (
        !userLogged ||
        localStorage.getItem('idRole') === '1' ||
        !localStorage.getItem('idRole')
      ) {
        return <Redirect to={'/'} />;
      }
      return <RenderRoutes {...props} />;
    },
    routes: [
      {
        path: '/dashboard',
        key: 'DASHBOARD_ROOT',
        exact: true,
        component: () => (
          <userContext.Consumer>
            {({ user, currency }) => {
              if (currency) {
                return <Dashboard user={user} currency={currency} />;
              } else {
                return null;
              }
            }}
          </userContext.Consumer>
        )
      },
      {
        path: '/dashboard/chiffre-mois',
        key: 'DASHBOARD_CA',
        exact: true,
        component: () => (
          <userContext.Consumer>
            {({ currency }) => {
              if (currency) {
                return <ChiffreDaffaire currency={currency} />;
              } else {
                return null;
              }
            }}
          </userContext.Consumer>
        )
      },
      {
        path: '/dashboard/chiffre-service',
        key: 'DASHBOARD_CA_SERVICE',
        exact: true,
        component: () => (
          <userContext.Consumer>
            {({ currency }) => {
              if (currency) {
                return <ChiffreDaffaireService currency={currency} />;
              } else {
                return null;
              }
            }}
          </userContext.Consumer>
        )
      },
      {
        path: '/dashboard/ventes',
        key: 'DASHBOARD_SELLS',
        exact: true,
        component: () => <Ventes />
      },
      {
        path: '/dashboard/commandes-statuts',
        key: 'DASHBOARD_SELLS',
        exact: true,
        component: () => <OrdersStatus />
      },
      {
        path: '/dashboard/vue-d-ensemble',
        key: 'DASHBOARD_OVERVIEW',
        exact: true,
        component: () => <Overview />
      },
      {
        path: '/dashboard/vue-d-ensemble/:country',
        key: 'DASHBOARD_OVERVIEW_COUNTRY',
        exact: true,
        component: props => (
          <userContext.Consumer>
            {({ currency }) => {
              if (currency) {
                return <CountryStats {...props} currency={currency} />;
              } else {
                return null;
              }
            }}
          </userContext.Consumer>
        )
      }
    ]
  }
];

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );
}

export function RenderRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
      <Route component={() => <NotFound />} />
    </Switch>
  );
}

export default ROUTES;
