import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';

import { HeaderIcons } from '../Components/layouts/header/Header.style';
import Container, { Box, Flex } from '../Components/styles/Container';
import ModifyInfo from '../Components/account/ModifyAccount';
import AccountInfo from '../Components/account/AccountInfo';
import api from '../client/api';
import LangSelect from '../Components/langSelect/LangSelect';
import Banner from '../Components/Banner';
import { Title4 } from '../Components/styles/Titles';
import imgCover from '../img/cover-trace.jpg';
import { useDarkMode } from '../Components/hooks/useDarkMode';

export default function Account() {
  const [modifying, setModifying] = useState(false);
  const [user, setUser] = useState(null);

  const [theme, themeToggler] = useDarkMode();

  const { t } = useTranslation();

  useEffect(() => {
    api.getUser(user => {
      const newUser = { ...user };
      newUser.password = null;
      setUser(newUser);
    });
  }, []);

  function confirmDelete() {
    confirmAlert({
      title: 'Confirmation',
      message: 'Es-tu sûr de vouloir supprimer ton compte ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => deleteAccount()
        },
        {
          label: 'Non'
        }
      ]
    });
  }

  function deleteAccount() {
    api.deleteUser(user.identity, response => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('idRole');
      localStorage.removeItem('expires_in');
      window.location.href = '/compte-supprime';
    });
  }

  function onChangePassword(e) {
    if (e.target.value !== '') {
      const newUser = { ...user };
      newUser.password = e.target.value;
      setUser(newUser);
    } else {
      setUser(null);
    }
  }

  function handleCountryChange(suggestions) {
    const newUser = { ...user };
    newUser.country.country = suggestions[0].name;
    newUser.countryCode = suggestions[0].alpha_2;
    setUser(newUser);
  }

  function onSuggestions(suggestions) {
    const newUser = { ...user };
    if (suggestions.length === 1) {
      newUser.country.country = suggestions[0].name;
      newUser.countryCode = suggestions[0].alpha_2;
      setUser(newUser);
    } else {
      newUser.country.country = null;
      setUser(newUser);
    }
  }

  function onChange(e, field) {
    const newUser = { ...user };
    newUser[field] = e.target.value;
    setUser(newUser);
  }

  function toggleModifying() {
    api.updateUser(user);
    setModifying(!modifying);
  }

  return (
    <Box pb={5}>
      <Banner
        children={
          <Box center>
            <Title4>{user ? user.firstName + ' ' + user.lastName : ''}</Title4>
          </Box>
        }
        img={imgCover}
        alt="Beyonce YouTrace Pro"
        imgHeight="150px"
        cta
      />
      <Container mt="25px" small>
        <Box center>
          <h3>{t('settingsYt')}</h3>
        </Box>
        <Flex>
          <Box style={{ flex: 1 }}>
            <p>{t('lang')} :</p>
            <LangSelect />
          </Box>
          <Box style={{ flex: 1 }}>
            <p>Theme :</p>
            <HeaderIcons onClick={() => themeToggler()}>
              {theme} mode
              <i className={theme ? 'icon-sun' : 'icon-moon'} style={{ marginLeft: '10px' }} />
            </HeaderIcons>
          </Box>
        </Flex>
      </Container>
      <Container mt="25px" small>
        <Box center>
          <h3>{t('myInfos')} </h3>
        </Box>
      </Container>
      {modifying ? (
        <ModifyInfo
          user={user}
          onSuggestions={onSuggestions}
          onCountryChange={handleCountryChange}
          onChangePassword={onChangePassword}
          toggleModifying={toggleModifying}
          onChange={onChange}
          handleDeleteAccount={confirmDelete}
        />
      ) : (
        <AccountInfo user={user} toggleModifying={toggleModifying} modifying={modifying} />
      )}
    </Box>
  );
}

// class Account extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       modifying: false,
//       user: null,
//       countries: []
//     };
//     this.toggleModifying = this.toggleModifying.bind(this);
//     this.onChangePassword = this.onChangePassword.bind(this);
//     this.onSuggestions = this.onSuggestions.bind(this);
//     this.onChange = this.onChange.bind(this);
//     this.deleteAccount = this.deleteAccount.bind(this);
//     this.handleCountryChange = this.handleCountryChange.bind(this);
//     this.confirmDelete = this.confirmDelete.bind(this);
//   }

//   componentDidMount() {
//     api.getUser(user => {
//       user.password = null;
//       this.setState({ user: user });
//     });
//   }

//   confirmDelete() {
//     confirmAlert({
//       title: 'Confirmation',
//       message: 'Es-tu sûr de vouloir supprimer ton compte ?',
//       buttons: [
//         {
//           label: 'Oui',
//           onClick: () => this.deleteAccount()
//         },
//         {
//           label: 'Non'
//         }
//       ]
//     });
//   }

//   deleteAccount() {
//     api.deleteUser(this.state.user.identity, response => {
//       localStorage.removeItem('access_token');
//       localStorage.removeItem('idRole');
//       localStorage.removeItem('expires_in');
//       window.location.href = '/compte-supprime';
//     });
//   }

//   onChangePassword(e) {
//     this.state.user.password = e.target.value != '' ? e.target.value : null;
//   }

//   handleCountryChange(suggestions) {
//     this.state.user.country.country = suggestions[0].name;
//     this.state.user.countryCode = suggestions[0].alpha_2;
//   }

//   onSuggestions(suggestions) {
//     if (suggestions.length === 1) {
//       this.state.user.country.country = suggestions[0].name;
//       this.state.user.countryCode = suggestions[0].alpha_2;
//     } else {
//       this.state.user.country.country = null;
//     }
//   }

//   onChange(e, field) {
//     this.state.user[field] = e.target.value;
//   }

//   toggleTheme = () => {
//     this.setState(
//       prevState => ({
//         theme: !prevState.theme
//       }),
//       () => {
//         localStorage.setItem('theme', this.state.theme);
//       }
//     );
//   };

//   toggleModifying() {
//     api.updateUser(this.state.user);
//     this.setState({
//       modifying: !this.state.modifying
//     });
//   }

//   render() {
//     const { user } = this.state;
//     return (
//       <Box pb={5}>
//         <Banner
//           children={
//             <Box center>
//               <Title4>{user ? user.firstName + ' ' + user.lastName : ''}</Title4>
//             </Box>
//           }
//           img={imgCover}
//           alt="Beyonce YouTrace Pro"
//           imgHeight="150px"
//           cta
//         />
//         <Container mt="25px" small>
//           <Box center>
//             <h3>{this.props.t('settingsYt')}</h3>
//           </Box>
//           <Flex>
//             <Box style={{ flex: 1 }}>
//               <p>{this.props.t('lang')} :</p>
//               <LangSelect />
//             </Box>
//             {/* <Box style={{ flex: 1 }}>
//               <p>Theme :</p>
//               <HeaderIcons onClick={() => this.toggleTheme()}>
//                 {this.props.theme ? 'Light Mode' : 'Dark Mode'}
//                 <i
//                   className={this.props.theme ? 'icon-sun' : 'icon-moon'}
//                   style={{ marginLeft: '10px' }}
//                 />
//               </HeaderIcons>
//             </Box> */}
//           </Flex>
//         </Container>
//         <Container mt="25px" small>
//           <Box center>
//             <h3>{this.props.t('myInfos')} </h3>
//           </Box>
//         </Container>
//         {this.state.modifying ? (
//           <ModifyInfo
//             user={this.state.user}
//             onSuggestions={this.onSuggestions}
//             onCountryChange={this.handleCountryChange}
//             onChangePassword={this.onChangePassword}
//             toggleModifying={this.toggleModifying}
//             onChange={this.onChange}
//             handleDeleteAccount={this.confirmDelete}
//             modifying={this.state.modifying}
//           />
//         ) : (
//           <AccountInfo
//             user={this.state.user}
//             toggleModifying={this.toggleModifying}
//             modifying={this.state.modifying}
//           />
//         )}
//       </Box>
//     );
//   }
// }

// export default withTranslation()(Account);
