/*eslint no-unused-expressions: 0*/
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { translateWord } from '../../../utils/translate';
import { useDarkMode } from '../../hooks/useDarkMode';

const Pie = props => {
  const [theme] = useDarkMode();
  const translateLegend = dataObject => {
    const copyObject = JSON.parse(JSON.stringify(dataObject));
    const translatedName = copyObject.data?.map(el => translateWord(el.name));
    translatedName?.map((el, i) => ([...copyObject.data][i].name = el));
    return copyObject;
  };
  const options = {
    chart: {
      type: 'pie',
      backgroundColor: theme === 'light' ? '#fff' : '#000',
      style: {
        fontFamily: '"Inter",Sans-Serif',
        fontWeight: 'bold'
      }
    },
    title: {
      text: ''
    },
    series: translateLegend(props.chartData),
    legend: {
      itemStyle: {
        color: theme === 'dark' ? '#fff' : '#000'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    credits: {
      enabled: false
    }
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Pie;
