import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextS, TextXXS } from '../styles/Texts';
import { BlocCol, BlocCols1, BlocS } from '../styles/Bloc';
import { Box } from '../styles/Container';
import Link from '../styles/Link';
import OrderState from './OrderState';

export default function OrderCoordinateur({ createdAt, paymentDate, ...props }) {
  const { t } = useTranslation();
  const status = [
    { color: '255, 137, 0', title: t('waitpayment') },
    { color: '19, 234, 168', title: t('payed') },
    { color: '1, 130, 1', title: t('orderdelivered') },
    { color: '83, 83, 83', title: t('canceled') },
    { color: '247, 114, 74', title: t('paymentMismatch') },
    { color: '47, 151, 220', title: t('initialized') },
    { color: '255, 0, 0', title: t('failed') },
    { color: '41, 72, 245', title: t('inprogress') },
    { color: '1, 168, 104', title: t('prodDone') },
    { color: '117, 5, 150', title: t('customerContacted') }
  ];
  return (
    <BlocS fWidth mb={3} style={{ overflow: 'hidden' }}>
      <OrderState style={{ background: `rgb(${status[props.status].color})` }} t={t}>
        <p>{status[props.status].title}</p>
      </OrderState>
      <BlocCols1>
        <BlocCol>
          <TextXXS opacity="0.6" mb={1}>
            {t('customerName')}
          </TextXXS>
          <TextS>{props.artist}</TextS>
        </BlocCol>
        <BlocCol>
          <TextXXS opacity="0.6" mb={1}>
            {t('orderNumber')}
          </TextXXS>
          <TextS>{props.cru}</TextS>
        </BlocCol>
        <BlocCol>
          <TextXXS opacity="0.6" mb={1}>
            {t('transactionDate')}
          </TextXXS>
          <TextS>{createdAt}</TextS>
        </BlocCol>
        {paymentDate && (
          <>
            <BlocCol>
              <TextXXS bold>{t('paymentDate')}</TextXXS>
            </BlocCol>
            <BlocCol>
              <TextS>{paymentDate}</TextS>
            </BlocCol>
          </>
        )}
        <Box mt={2} mb={4} center>
          <Link href={'/commandes/details/' + props.identity} orange>
            {t('seeDetails')}
          </Link>
        </Box>
      </BlocCols1>
    </BlocS>
  );
}
