import ariel from '../../img/artists/Ariel_Sheney.jpg';
import banlieuzart from '../../img/artists/BanlieuZart.jpg';
import dipdoundou from '../../img/artists/dipdoundouguiss.jpg';
import fally from '../../img/artists/FallyIpupa.jpg';
import fanicko from '../../img/artists/fanicko.jpg';
import ferregola from '../../img/artists/ferregola.jpg';
import floby from '../../img/artists/floby.png';
import gims from '../../img/artists/maitre-gims.jpg';
import manibella from '../../img/artists/mani-bella.png';
import minks from '../../img/artists/Minks.jpg';
import shanl from '../../img/artists/ShanL.jpg';
import sidiki from '../../img/artists/sidiki-diabate.jpg';
import stanley from '../../img/artists/stanley-enow.jpg';
import tenor from '../../img/artists/Tenor.jpeg';
import toofan from '../../img/artists/Toofan.jpg';

const artistes = [
  { img: ariel, name: 'Ariel Sheney' },
  { img: banlieuzart, name: 'BanlieuZ’art' },
  { img: dipdoundou, name: 'Dip doundou guiss' },
  { img: fally, name: 'Fally Ipupa' },
  { img: fanicko, name: 'Fanicko' },
  { img: ferregola, name: 'Ferre Gola' },
  { img: floby, name: 'Floby' },
  { img: gims, name: 'Maitre Gims' },
  { img: manibella, name: 'Mani Bella' },
  { img: minks, name: 'Minks' },
  { img: shanl, name: 'ShanL' },
  { img: sidiki, name: 'Sidiki Diabaté' },
  { img: stanley, name: 'Stanley Enow' },
  { img: tenor, name: 'Tenor' },
  { img: toofan, name: 'Toofan' }
];
export default artistes;
