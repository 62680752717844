/*eslint eqeqeq: 0*/
import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

import Container, { Box } from '../styles/Container';
import Button from '../styles/Button';
import { Title4 } from '../styles/Titles';
import { Text } from '../styles/Texts';
import { userContext } from '../../context/userContext';

import imgDiffuser from '../../img/diffusion-youtube.png';
import imgPromo from '../../img/trace-social.png';
import imgRealiser from '../../img/maitre-gims.png';
import imgDistribution from '../../img/distribution.png';

import { Service } from './services.style';

function ServicesShowcase() {
  function isOdd(num) {
    return num % 2 != 0;
  }
  const { t } = useTranslation();
  const services = [
    {
      title: t('makeMyVideo.title'),
      textLarge: t('makeMyVideo.text'),
      img: imgRealiser,
      link2: 'services/realisation'
    },
    {
      title: t('shareMyVideo.title'),
      textLarge: t('shareMyVideo.text'),
      img: imgDiffuser,
      link2: 'services/diffusion'
    },
    {
      title: t('digitalPromo.title'),
      textLarge: t('digitalPromo.text'),
      img: imgPromo,
      link2: 'services/promotion'
    },
    {
      title: t('distribution.title'),
      textLarge: t('distribution.text'),
      img: imgDistribution,
      link2: 'services/distribution'
    }
  ];
  return (
    <userContext.Consumer>
      {({ user }) => {
        return (
          <Container mb={5}>
            {services.map((service, i) => (
              <Service key={i}>
                <Fade left={isOdd(i)} right={isOdd(i + 1)}>
                  <div className="image">
                    <img src={service.img} alt={service.title} />
                  </div>
                </Fade>
                <Box className="content">
                  <Fade>
                    <Title4 className="title">{service.title}</Title4>
                    <Text my={3} dangerouslySetInnerHTML={{ __html: service.textLarge }} />
                    <Button className="button" as={Link} to={user ? '/services' : '/inscription'}>
                      {t('start')}
                    </Button>
                    <Link to={service.link2}>{t('knowMore')}</Link>
                  </Fade>
                </Box>
              </Service>
            ))}
          </Container>
        );
      }}
    </userContext.Consumer>
  );
}

export default ServicesShowcase;
