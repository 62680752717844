import React, { useContext } from 'react';
import uuid from 'uuid';
import { ThemeContext } from 'styled-components';

import { RadioItem, RadioItemPrice, RadioItemText } from '../styles/Form';
import { Text, TextL, TextS } from '../styles/Texts';
import { numberToDevice } from '../subscription/utils';
import { Box, Flex } from '../styles/Container';

import logoFacebook from '../../img/logo-facebook.png';
import logoInstagram from '../../img/logo-instagram.png';
import logoTrace from '../../img/trace-icon.png';
import logoYoutube from '../../img/logo-youtube.png';
import { useTranslation } from 'react-i18next';

export default function PromoDigitale({ onChange, isMobile, ...props }) {
  function handleRadios(id, field) {
    if (!props[field] || props[field] !== id) {
      const e = {
        target: {
          type: 'radio',
          id
        }
      };
      onChange(e, field);
    }
  }

  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const optionDiffusionData = [
    {
      id: 'youtrace',
      name: 'option-diffusion',
      title: 'Classic',
      instagram: '2 stories',
      facebook: '2 stories'
    },
    {
      id: 'youtrace-trace',
      name: 'option-diffusion',
      title: 'Premium',
      instagram: '3 stories',
      facebook: '3 stories',
      website: t('digitalPromo.article')
    }
  ];
  return (
    <>
      {optionDiffusionData.map(
        ({ id, name, title, subtitle, facebook, instagram, website, vevo }) => {
          const checked = props[name] && props[name] === id;
          const montant =
            id === 'youtrace'
              ? props['selectedCountry'].countryServices.find(
                  cs => cs.service.serviceType === 'PROMO_CLASSIC'
                ).amount
              : props['selectedCountry'].countryServices.find(
                  cs => cs.service.serviceType === 'PROMO_PREMIUM'
                ).amount;
          return (
            <RadioItem
              key={uuid()}
              onClick={() => handleRadios(id, name)}
              style={{
                display: 'block',
                color: themeContext.colors.secondary,
                border: '1px solid'
              }}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <RadioItemText>
                  <TextL>{title}</TextL>
                </RadioItemText>
                <RadioItemPrice>
                  <input
                    type="radio"
                    id={id}
                    name={name}
                    onChange={() => handleRadios(id, name)}
                    checked={checked}
                  />
                  <label htmlFor={id}>
                    <TextS>
                      {numberToDevice(montant)} {props['selectedCountry'].currency}
                    </TextS>
                    <span></span>
                  </label>
                </RadioItemPrice>
              </span>
              <RadioItemText>
                <Text>{subtitle}</Text>
                <Flex alignItems="center" mt={2}>
                  <Box inlineBlock secondary mr={1} mt="5px">
                    <img
                      src={logoInstagram}
                      alt="Logo Instagram"
                      style={{ width: '20px', marginRight: '3px' }}
                    />
                  </Box>
                  <Text orange py={1}>
                    {instagram}
                  </Text>
                </Flex>
                <Flex alignItems="center">
                  <Box inlineBlock secondary mt="3px">
                    <img
                      src={logoFacebook}
                      alt="Logo Facebook"
                      style={{ width: '20px', marginRight: '5px' }}
                    />
                  </Box>
                  <Text orange py={1}>
                    {facebook}
                  </Text>
                </Flex>
                {website && (
                  <Flex alignItems="center">
                    <Box inlineBlock secondary mt="3px">
                      <img
                        src={logoTrace}
                        alt="Logo Facebook"
                        style={{ width: '20px', marginRight: '5px' }}
                      />
                    </Box>
                    <Text orange py={1}>
                      {website}
                    </Text>
                  </Flex>
                )}
                {vevo && (
                  <Flex alignItems="center">
                    <Box inlineBlock secondary mt="3px">
                      <img
                        src={logoYoutube}
                        alt="Logo Youtube"
                        style={{ width: '20px', marginRight: '5px' }}
                      />
                    </Box>
                    <Text orange py={1}>
                      {vevo}
                    </Text>
                  </Flex>
                )}
              </RadioItemText>
            </RadioItem>
          );
        }
      )}
    </>
  );
}
