import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Link from '../../styles/Link';
import { StyledMenu } from './responsive.style';

const ResponsiveMenu = ({ open, isLogged, logout, setOpen, theme, user }) => {
  const { t } = useTranslation();
  return (
    <StyledMenu open={open} darkTheme={theme}>
      {/* <HeaderIcons style={{ position: 'absolute', top: '13px', right: '60px' }}>
        <i
          className={theme ? 'icon-sun' : 'icon-moon'}
          onClick={toggleTheme}
          style={{ fontSize: '1.2rem' }}
        />
      </HeaderIcons> */}

      {isLogged ? (
        user && user.idRole === '1' ? (
          <NavLink to="/mon-compte" activeClassName="active" onClick={() => setOpen(!open)}>
            <i className="icon-user" />
            {t('myAccount')}
          </NavLink>
        ) : null
      ) : (
        <>
          <Link href="/connexion" activeClassName="active" onClick={() => setOpen(!open)}>
            <i className="icon-login" />
            {t('logIn')}
          </Link>
          <NavLink to="/inscription" activeClassName="active" onClick={() => setOpen(!open)}>
            <i className="icon-enregistrer" />
            {t('createAnAccount')}
          </NavLink>
        </>
      )}
      <NavLink to="/foire-aux-questions" activeClassName="active" onClick={() => setOpen(!open)}>
        <i className="icon-question" />
        FAQ
      </NavLink>
      <Link href="http://youtrace.tv/" activeClassName="active" onClick={() => setOpen(!open)}>
        <i className="icon-chevron-left" />
        {t('backToYoutrace')}
      </Link>
      {isLogged && (
        <a
          href="/#"
          onClick={() => {
            setOpen(!open);
            logout();
          }}
        >
          <i className="icon-logout" />
          {t('logout')}
        </a>
      )}
    </StyledMenu>
  );
};
export default ResponsiveMenu;
