import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Logo from '../Logo';
import { TextS } from '../../styles/Texts';
import LangSelect from '../../langSelect/LangSelect';
import Burger from '../responsive/Burger';
import ResponsiveMenu from '../responsive/ResponsiveMenu';
import { userContext } from '../../../context/userContext';
import {
  HeaderTop,
  HeaderIcons,
  HeaderLogo,
  HeaderNav,
  HeaderIconsWrapper,
  LangWrapperMobile,
  NavLinkDropdown,
  NavLinkWrapper,
  NavLinkChild
} from './Header.style';

function Header({ logout, theme, setOpen, responsiveMenuOpen }) {
  const { t } = useTranslation();
  const getFirstLetter = word => {
    return word.charAt(0).toUpperCase();
  };
  const isLogged = localStorage.getItem('access_token');
  const isAdmin =
    isLogged && localStorage.getItem('idRole') && localStorage.getItem('idRole') !== '1';
  return (
    <userContext.Consumer>
      {({ user }) => {
        return (
          <div data-testid="header">
            <HeaderTop>
              <HeaderLogo>
                <HeaderLogo>
                  <Logo user={user} theme={theme} />
                </HeaderLogo>
              </HeaderLogo>

              <HeaderNav>
                {isAdmin && <NavLink to="/dashboard">Dashboard</NavLink>}
                {isLogged && <NavLink to="/services">{t('order')}</NavLink>}
                <NavLinkWrapper>
                  <span style={{ marginRight: 20 }}>
                    {t('services')}
                    <i className="icon-chevron-bottom" />
                  </span>
                  <NavLinkDropdown>
                    <NavLinkChild to="/services/realisation">{t('realisation')}</NavLinkChild>
                    <NavLinkChild to="/services/diffusion">{t('diffusion')}</NavLinkChild>
                    <NavLinkChild to="/services/promotion">{t('promotion')}</NavLinkChild>
                    <NavLinkChild to="/services/distribution">{t('distribution')}</NavLinkChild>
                  </NavLinkDropdown>
                </NavLinkWrapper>
                <NavLinkWrapper className="user">
                  {isLogged ? (
                    <>
                      <HeaderIcons>
                        <TextS mr={2}>
                          {user && `${user.firstName} ${getFirstLetter(user.lastName)}.`}
                        </TextS>
                        <i className="icon-user opacity" />
                      </HeaderIcons>
                      <NavLinkDropdown>
                        <NavLinkChild to="/mon-compte">{t('settings')}</NavLinkChild>
                        <NavLinkChild to="/commandes">{t('myOrders')}</NavLinkChild>
                        {user && user.optionDistribution && (
                          <NavLinkChild to="/sso" target="_blank">
                            {t('distrib')}
                          </NavLinkChild>
                        )}
                        <NavLinkChild as="a" onClick={logout}>
                          {t('logout')}
                        </NavLinkChild>
                      </NavLinkDropdown>
                    </>
                  ) : (
                    <NavLinkWrapper>
                      <NavLink to="/connexion">{t('logIn')}</NavLink>
                    </NavLinkWrapper>
                  )}
                </NavLinkWrapper>
              </HeaderNav>

              <HeaderIconsWrapper>
                <HeaderIcons>
                  <LangSelect />
                </HeaderIcons>
              </HeaderIconsWrapper>
            </HeaderTop>
            <>
              <LangWrapperMobile>
                <LangSelect />
              </LangWrapperMobile>
              <Burger open={responsiveMenuOpen} setOpen={setOpen} />
              <ResponsiveMenu
                logout={logout}
                isLogged={isLogged}
                open={responsiveMenuOpen}
                setOpen={setOpen}
                theme={theme === 'dark'}
                user={user}
              />
            </>
          </div>
        );
      }}
    </userContext.Consumer>
  );
}

Header.contextTypes = {
  router: PropTypes.object
};
export default Header;
