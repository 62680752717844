import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 60px 13px;
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  flex-wrap: wrap;
  @media ${props => props.theme.mediaQueries.mediumWidth} {
    padding: 16px 20px 10px 20px;
  }
  ${props =>
    props.center &&
    css`
      justify-content: center;
    `}
`;
const HeaderIconsWrapper = styled.div`
  display: flex;
  @media ${props => props.theme.mediaQueries.smallWidth} {
    display: none;
  }
`;
const LangWrapperMobile = styled.div`
  position: absolute;
  right: 60px;
  top: 8px;
  @media ${props => props.theme.mediaQueries.minSmallWidth} {
    display: none;
  }
`;
const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  i {
    font-size: 19px;
    cursor: pointer;
    transition: 0.3s ${props => props.theme.transitions.easeInOutCubic};
    color: ${props => props.theme.colors.greyLight};
    & + i {
      margin-left: 30px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  @media ${props => props.theme.mediaQueries.mediumWidth} {
    i {
      font-size: 15px;
      & + i {
        margin-left: 27px;
      }
    }
  }
`;

const HeaderLogo = styled.div`
  width: 200px;
  margin-right: auto;
  @media ${props => props.theme.mediaQueries.mediumWidth} {
    width: 170px;
  }
  @media ${props => props.theme.mediaQueries.smallWidth} {
    width: 110px;
  }
  img {
    height: 32px;
    width: auto;
    @media ${props => props.theme.mediaQueries.mediumWidth} {
      height: 25px;
    }
    @media ${props => props.theme.mediaQueries.smallWidth} {
      height: 16px;
    }
  }
`;

const HeaderNav = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  a {
    cursor: pointer;
    position: relative;
    display: inline-block;
    color: ${props => props.theme.colors.secondary};
    text-decoration: none;
    white-space: nowrap;
    margin-right: 20px;
    &:last-child {
      margin-right: 5px;
    }
    &:after {
      content: '';
      position: absolute;
      transform: scaleX(0);
      right: 0;
      bottom: 1px;
      height: 1px;
      transition: transform 0.3s ${props => props.theme.transitions.easeInOutCubic};
      transition-delay: 0.2s;
      transform-origin: right;
      z-index: -1;
      width: 100%;
      background: ${props => props.theme.colors.secondary};
    }
    &:hover {
      &:after {
        transform: scaleX(1);
        transition-delay: 0s;
        z-index: 1;
        left: 0;
        right: auto;
        transform-origin: left;
      }
    }
    &.active {
      color: ${props => props.theme.colors.orange};
      &:hover:after {
        background: ${props => props.theme.colors.orange};
      }
    }
    &:not(.active):not(.deconnexion) {
      color: ${props => props.theme.colors.secondary};
    }
  }
  @media ${props => props.theme.mediaQueries.mediumWidth} {
    padding: 10px 20px 10px 20px;
    li {
      font-size: 14px;
      letter-spacing: -0.3px;
    }
  }
  @media ${props => props.theme.mediaQueries.smallWidth} {
    width: 100%;
    padding-left: 0;
  }
`;
const NavLinkDropdown = styled.ul`
  position: absolute;
  background: ${props => props.theme.colors.thirdly};
  left: -20px;
  padding: 8px 15px 10px 15px;
  z-index: 9999;
  bottom: 0;
  transform: translateY(100%) scaleY(0);
  transition: all 0.6s;
  transform-origin: top;
  color: #ffffff;
`;
const NavLinkChild = styled(NavLink)`
  padding: 10px 0;
  color: ${props => props.theme.colors.secondary};
  font-size: 12px;
  cursor: pointer;
`;
const NavLinkChildExt = styled.a`
  padding: 10px 0;
  color: ${props => props.theme.colors.secondary};
  font-size: 12px;
  cursor: pointer;
`;

const NavLinkWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:hover {
    ul {
      transform: translateY(calc(100% + 2px)) scaleY(1);
    }
  }
`;

export {
  HeaderTop,
  HeaderIcons,
  HeaderLogo,
  HeaderNav,
  HeaderIconsWrapper,
  LangWrapperMobile,
  NavLinkChild,
  NavLinkWrapper,
  NavLinkDropdown,
  NavLinkChildExt
};
