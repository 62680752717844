import React from 'react';
import styled from 'styled-components';
import uuid from 'uuid';

import { Text, TextL, TextXS } from './styles/Texts';
import Container, { Box } from './styles/Container';
import Slider from 'react-slick';

import '../vendors/slick.css';
import '../vendors/slick-theme.css';
import { useTranslation } from 'react-i18next';
import iconClip from '../img/clip.svg';
import iconDiffusion from '../img/diffusion.png';
import iconYoutube from '../img/youtube.png';

const AvantagesItem = styled.div`
  padding: 10px;
  line-height: 124%;
  text-align: center;
  .title {
    line-height: 124%;
  }
  @media ${props => props.theme.mediaQueries.mediumWidth} {
    width: 220px;
    margin: 0 auto;
  }
  @media ${props => props.theme.mediaQueries.minMediumWidth} {
    flex-shrink: 0;
    line-height: 124%;
  }
`;
const AvantagesIcon = styled.div`
  height: 54px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 26px;
  img {
    max-height: 52px;
  }
`;
const AvantagesWrapper = styled.div`
  @media ${props => props.theme.mediaQueries.mediumWidth} {
    margin-bottom: 30px;
  }
  @media ${props => props.theme.mediaQueries.minMediumWidth} {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
`;

function Avantage({ icon, title, text }) {
  return (
    <AvantagesItem>
      <AvantagesIcon>
        <img src={icon} alt={title} />
      </AvantagesIcon>
      <Text bold mb={2}>
        {title}
      </Text>
      {text && <TextXS mb={2} opacity="0.6" dangerouslySetInnerHTML={{ __html: text }} />}
    </AvantagesItem>
  );
}

function AvantageSlider() {
  const { t } = useTranslation();
  const avantages = [
    {
      icon: iconClip,
      title: t('makeMyVideo.title'),
      text: t('makeMyVideo.text')
    },
    {
      icon: iconDiffusion,
      title: t('shareMyVideo.title'),
      text: t('shareMyVideo.text')
    },
    {
      icon: iconYoutube,
      title: t('digitalPromo.title'),
      text: t('digitalPromo.text')
    }
  ];
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <Box my={5}>
      <Container fluid>
        <TextL my={4} style={{ textAlign: 'center' }}>
          {t('home.title2.part1')} <span>{t('home.title2.part2')}</span><br />{' '}
          {t('home.title2.part3')} <span>{t('home.title2.part4')}</span>
        </TextL>
        <Box>
          <Slider {...settings}>
            {avantages.map(avantage => (
              <Avantage key={uuid} {...avantage} />
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
}

export { AvantagesItem, AvantagesIcon, AvantagesWrapper, Avantage, AvantageSlider };
