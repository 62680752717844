import React, { Component } from 'react';
import api from '../../auth/auth';
import LoginRender from './LoginRender';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
      error: false
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onError = this.onError.bind(this);
  }

  onChange(e, field) {
    this.setState({ [field]: e.target.value });
  }

  onError(error) {
    this.setState({ error: true });
  }

  handleLogin(loginUser) {
    api.fetchAccessToken(loginUser, this.onError, this.state.login, this.state.password);
  }

  render() {
    return (
      <LoginRender
        handleLogin={this.handleLogin}
        onChange={this.onChange}
        {...this.props}
        {...this.state}
      />
    );
  }
}

export default LoginForm;
