import React from 'react';

import { Text, TextL } from '../styles/Texts';
import Box from '../styles/Container';

function YearToDate({ data, title }) {
  return (
    <Box my={4} style={{ textAlign: 'center', flex: 1 }}>
      <TextL mt={0} mb={2} orange bold>
        {data}
      </TextL>
      <Text>{title}</Text>
    </Box>
  );
}

export default YearToDate;
