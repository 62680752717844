import React from 'react';
import InfiniteCalendar from 'react-infinite-calendar';
import { useTranslation } from 'react-i18next';
import 'react-infinite-calendar/styles.css'; // only needs to be imported once

import Container, { Box } from '../styles/Container';
import { BannerImg, BannerWrapper } from '../Banner';
import { AvantageSlider } from '../Avantages';
import bgImg from '../../img/informations-complementaires.jpg';
import { Title2 } from '../styles/Titles';
import Theme from '../../theme';
import Button from '../styles/Button';
import Link from '../styles/Link';
import { Text, TextL } from '../styles/Texts';
import ScrollToTop from '../ScrollToTop';

export default function Calendar({ goToRecap, goToInformations, handleDate, errors, ...props }) {
  const { t, i18n } = useTranslation();
  const localeActive = require(`date-fns/locale/${i18n.language.slice(0, 2)}/index.js`);

  const today = new Date();
  today.setDate(today.getDate() + 3);
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 12);

  function disabledDates(service) {
    let disabledDates = props['schedules']
      .filter(sc => sc.unavailableServices.find(s => s.serviceType === service) != null)
      .map(d => new Date(d.date));
    return disabledDates;
  }

  let dates = [];
  if (props['optionDiffusion']) {
    disabledDates('UPLOAD').forEach(d => {
      dates.push(d);
    });
  }
  if (props['optionPromotion']) {
    if (props['option-diffusion'] === 'youtrace') {
      disabledDates('PROMO_CLASSIC').forEach(d => {
        dates.push(d);
      });
    }
    if (props['option-diffusion'] === 'youtrace-trace') {
      disabledDates('PROMO_PREMIUM').forEach(d => {
        dates.push(d);
      });
    }
  }
  return (
    <>
      <ScrollToTop />
      <BannerWrapper>
        <BannerImg src={bgImg} alt="YouTrace Inscription" className="index" />
        <Container small mb={4}>
          <Box center my={5}>
            <Title2>{t('chooseDate')}*</Title2>
          </Box>
          <InfiniteCalendar
            theme={{
              selectionColor: Theme.dark.colors.orange,
              weekdayColor: Theme.dark.colors.orange,
              headerColor: Theme.dark.colors.orange,
              floatingNav: {
                background: Theme.dark.colors.orange
              }
            }}
            width="100%"
            height={400}
            selected={props.diffusionDate}
            locale={{
              locale: localeActive,
              headerFormat: 'dddd D MMM',
              weekdays: [t('dim'), t('lun'), t('mar'), t('mer'), t('jeu'), t('ven'), t('sam')],
              blank: t('noDateSelected'),
              todayLabel: {
                long: t('today')
              }
            }}
            min={today}
            minDate={today}
            maxDate={maxDate}
            max={maxDate}
            disabledDates={dates}
            onSelect={date => handleDate(date)}
          />
          {props['error'] && <Text error>{props['error']}</Text>}
          <Box mt={3}>
            <TextL>*{t('paymentDelai')}.</TextL>
          </Box>
          <Box mt={3} center>
            <Button onClick={() => goToRecap()}>{t('next')}</Button>
            <Box mt={3} center>
              <Link orange onClick={goToInformations}>
                <small>{t('previous')}</small>
              </Link>
            </Box>
          </Box>
        </Container>
      </BannerWrapper>
      <AvantageSlider />
    </>
  );
}
