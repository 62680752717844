import trailerVideo from '../../img/realisation.mp4';
import imgDiffuser from '../../img/diffusion-youtube.png';
import imgPromo from '../../img/trace-social.png';
import imgRealiser from '../../img/maitre-gims.png';
import imgDistribution from '../../img/distribution.png';
import iconInscription from '../../img/icon-check.svg';
import iconValidation from '../../img/icon-login.svg';
import iconPlan from '../../img/icon-calendar.svg';
import iconTourne from '../../img/clip.svg';
import iconDiffusion from '../../img/diffusion.png';

const fr = {
  video: trailerVideo,
  title: 'Réaliser mon clip',
  subtitle: 'YouTrace <span>réalise ton clip vidéo</span><br/> en un temps record et dans le pays de ton choix',
  callToAction: 'Commencer',
  introduction: {
    img: imgRealiser,
    title: 'Les <span>offres</span>',
    paragraph:
      'Quelle que soit l\'offre que tu choisies, tu es mis en contact avec ton représentant officiel YouTrace. Il/elle  t\'accompagnera dans toutes les étapes de la création de ton clip. Ceci inlcut la création du synopsis, le choix des lieux de tournage, les décors, le brief des équipes, la sélection du matériel en encore la logistique… <br/><br/>Après le tournages le clip est monté par nos équipes et est disponible au plus tard 8 jours après le tournage. Nous le partageons ensuite (si tu le souhaites) gratuitement sur notre chaîne YouTube et notre site YouTrace.tv.'
  },
  steps: {
    title: 'Comment réaliser mon <br/><span>clip vidéo</span>',
    step: [
      {
        icon: iconInscription,
        title: 'Inscris-toi et sélectionne ton offre',
        paragraph: 'Choisis ton pays de tournage et l’offre qui te convient.'
      },
      {
        icon: iconValidation,
        title: 'Valide ta commande',
        paragraph:
          'Une fois ton offre sélectionnée tu peux payer ta commande en ligne ou directement au représentant officiel de YouTrace. Tu reçois un mail de confirmation qui t\'indique que YouTrace a bien enregistré ton paiement.'
      },
      {
        icon: iconPlan,
        title: 'Planifie le tournage du clip',
        paragraph:
          'Ton représentant YouTrace te contacte par téléphone pour se présenter. Il/elle t\'accompagne durant toutes les étapes de la création de ta vidéo.'
      },
      {
        icon: iconTourne,
        title: 'Tourne ton clip',
        paragraph:
          'Une fois que tout est prêt, nos équipes te rejoignent sur le lieu de tournage. Le réalisateur présent connaît ton projet et encadre l’ensemble des opérations.'
      },
      {
        icon: iconDiffusion,
        title: 'Nous livrons et diffusons ton clip',
        paragraph:
          'Nos équipes font le montage et livrent ton clip étalonné au maximum 8 jours après le tournage. Si tu le souhaites, tu bénéficies du partage gratuit de ton clip sur notre chaîne Youtube et sur le site YouTrace.tv.<br/><br/> YouTrace te permet aussi de monétiser ta vidéo sur notre chaine YouTube et de toucher des royalties en fonction du nombre de vues.'
      }
    ]
  },
  artists: {
    title: 'Les artistes qui nous ont fait <span>confiance</span>',
    subtitle: 'YouTrace a coordonné la production de nombreux clips d\'artistes partout dans le monde'
  },
  services: {
    title: 'Les autres services YouTrace',
    items: [
      {
        title: 'Diffuser mon clip',
        cta: 'Découvrir',
        link: '/services/diffusion',
        img: imgDiffuser
      },
      {
        title: 'Faire ma promo digitale',
        cta: 'Découvrir',
        link: '/services/promotion',
        img: imgPromo
      },
      {
        title: 'Distribuer ma musique',
        cta: 'Découvrir',
        link: '/services/distribution',
        img: imgDistribution
      }
    ]
  }
};
const en = {
  video: trailerVideo,
  title: 'Shoot my video',
  subtitle: 'YouTrace <span>produces your video</span>, <br/> in record time and in the country of your choice',
  callToAction: 'Get Started',
  introduction: {
    img: imgRealiser,
    title: 'Our <span>Packages</span>',
    paragraph:
      'Whichever package you choose, you will be connected with an official YouTrace representative. She/he will support you through the journey of your video creation including creating the synopsis, identifying filming locations, choosing set elements, briefing the production team, choosing the right equipment, managing copyright and logistics and any other needs you may have. <br/><br/>After shooting, the video is edited by our team and delivered within a maximum of 8 days. We then share it on our YouTube channel and YouTrace.tv website.'
  },
  steps: {
    title: 'How to shoot your <span>video</span>',
    step: [
      {
        icon: iconInscription,
        title: 'Sign Up and Select a Package',
        paragraph: 'Select which country you\'d like to shoot your video in and which package you\'re interested in.'
      },
      {
        icon: iconValidation,
        title: 'Confirm your Order',
        paragraph:
          'Once you have selected your package, you can either pay online or pay your YouTrace official representative directly. You receive a confirmation email once your payment has been processed.'
      },
      {
        icon: iconPlan,
        title: 'Plan Your Video Shoot',
        paragraph:
          'Your YouTrace representative will give you a phone call to introduce him/herself. He or she is your contact point through the journey of your video creation.'
      },
      {
        icon: iconTourne,
        title: 'Shoot Your Video',
        paragraph:
          'Once everything is ready, our staff will join you at the shoot location. The director will kick off the project and coordinate all operations.'
      },
      {
        icon: iconDiffusion,
        title: 'We Deliver and Feature Your Video',
        paragraph:
          'Our team edits and delivers your video-clip within a maximum of 8 days. YouTrace shares your music video on our YouTube channel and YouTrace.tv website.<br/><br/>YouTrace allows you to monetize video on our YouTube channel by receiving royalties according to the number of views.'
      }
    ]
  },
  artists: {
    title: 'The artists who have <span>trusted us</span>',
    subtitle: 'YouTrace had produced videos for artists all over the world'
  },
  services: {
    title: 'Other YouTrace services',
    items: [
      {
        title: 'Feature my Video',
        cta: 'Discover',
        link: '/services/diffusion',
        img: imgDiffuser
      },
      {
        title: 'Create a Campaign for Me',
        cta: 'Discover',
        link: '/services/promotion',
        img: imgPromo
      },
      {
        title: 'Distribute my music',
        cta: 'Discover',
        link: '/services/distribution',
        img: imgDistribution
      }
    ]
  }
};
const pt = {
  video: trailerVideo,
  title: 'Filmar meu vídeo',
  subtitle: 'O YouTrace <span>produz seu vídeo</span>, em tempo recorde e no país de sua escolha',
  callToAction: 'Começar',
  introduction: {
    img: imgRealiser,
    title: 'Nossos <span>Pacotes</span>',
    paragraph:
      'Qualquer que seja o pacote escolhido, você será conectado a um representante oficial do YouTrace. Ele / ela irá apoiá-lo durante a jornada de criação de seu vídeo, incluindo a criação da sinopse, a identificação dos locais de filmagem, a escolha dos elementos do cenário, o briefing da equipe de produção, a escolha do equipamento certo, o gerenciamento de direitos autorais e logística e quaisquer outras necessidades que você possa ter. <br/><br/> Após a filmagem, o vídeo é editado por nossa equipe e entregue em no máximo 8 dias. Em seguida, compartilhamos em nosso canal no YouTube e no site YouTrace.tv. <br/>'
  },
  steps: {
    title: 'Como Gravar seus <br/><span>Vídeos</span>',
    step: [
      {
        icon: iconInscription,
        title: 'Cadastre-se e selecione um pacote',
        paragraph: 'Selecione em qual país você gostaria de gravar seu vídeo e em qual pacote você está interessado.'
      },
      {
        icon: iconValidation,
        title: 'Confirme seu pedido',
        paragraph:
          'Depois de selecionar seu pacote, você pode pagar online ou pagar diretamente ao seu representante oficial do YouTrace. Você receberá um e-mail de confirmação assim que seu pagamento for processado.'
      },
      {
        icon: iconPlan,
        title: 'Planeje sua gravação de vídeo com um representante do YouTrace',
        paragraph:
          'O representante do YouTrace ligará para você para se apresentar. Ele ou ela é o seu ponto de contato através da jornada de criação do seu vídeo.'
      },
      {
        icon: iconTourne,
        title: 'Grave seu vídeo',
        paragraph:
          'Quando tudo estiver pronto, nossa equipe irá acompanhá-lo no local da filmagem. O diretor dará início ao projeto e coordenará todas as operações.'
      },
      {
        icon: iconDiffusion,
        title: 'Nós entregamos e destacamos seu vídeo',
        paragraph:
          'Nossa equipe edita e entrega seu videoclipe em no máximo 8 dias. YouTrace compartilha seu videoclipe em nosso canal no YouTube e no site YouTrace.tv. <italic>O YouTrace permite que você monetize vídeo em nosso canal do YouTube, recebendo royalties de acordo com o número de visualizações.</italic>'
      }
    ]
  },
  artists: {
    title: 'Artistas quem <span>confiam em nós</span>',
    subtitle: 'YouTrace produz vídeos para artistas de todo o mundo'
  },
  services: {
    title: 'Outros Serviços da YouTrace',
    items: [
      {
        title: 'Apresentar meu vídeo',
        cta: 'Descobrir',
        link: '/services/diffusion',
        img: imgDiffuser
      },
      {
        title: 'Crie uma campanha para mim',
        cta: 'Descobrir',
        link: '/services/promotion',
        img: imgPromo
      },
      {
        title: 'Distribuir minha música',
        cta: 'Descobrir',
        link: '/services/distribution',
        img: imgDistribution
      }
    ]
  }
};

export { fr, en, pt };
