import React from 'react';
import styled from 'styled-components';

import Container, { Box, Flex } from './styles/Container';
import { Title3 } from './styles/Titles';
import { FaqDropdown } from './Dropdown';
import { useTranslation } from 'react-i18next';
import imgClip from '../img/yt-background.jpg';

const FaqClip = styled.div`
  -webkit-clip-path: url(#clipping);
  clip-path: url(#clipping);
  width: 300px;
  height: 300px;
  position: relative;
  margin: 100px auto 50px auto;
  overflow: hidden;
  flex: 2;
  @media ${props => props.theme.mediaQueries.smallWidth} {
    display: none;
  }
  img {
    width: 600px;
    height: auto;
    position: absolute;
    left: -168px;
    top: -100px;
  }
`;

function Faq() {
  const { t } = useTranslation();
  const questions = [
    {
      title: t('faq.question1.title'),
      text: t('faq.question1.text')
    },
    {
      title: t('faq.question2.title'),
      text: t('faq.question2.text')
    },
    {
      title: t('faq.question3.title'),
      text: t('faq.question3.text')
    },
    {
      title: t('faq.question4.title'),
      text: t('faq.question4.text')
    },
    {
      title: t('faq.question5.title'),
      text: t('faq.question5.text')
    },
    {
      title: t('faq.question6.title'),
      text: t('faq.question6.text')
    },
    {
      title: t('faq.question7.title'),
      text: t('faq.question7.text')
    },
    {
      title: t('faq.question8.title'),
      text: t('faq.question8.text')
    },
    {
      title: t('faq.question9.title'),
      text: t('faq.question9.text')
    },
    {
      title: t('faq.question10.title'),
      text: t('faq.question10.text')
    },
    {
      title: t('faq.question11.title'),
      text: t('faq.question11.text')
    }
  ];

  return (
    <Container mb={4}>
      <Flex alignItems="center" flexWrap="wrap">
        <Box center fwidth>
          <Title3>
            {t('questions')}
          </Title3>
        </Box>
        <Box style={{ flex: '1' }}>
          <FaqClip>
            <img src={imgClip} alt="YouTrace"/>
            <svg
              version="1.1"
              id="calque"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 282 296"
            >
              <clipPath id="clipping">
                <polyline
                  id="c"
                  points="282.1,50.3 282.1,82.5 225.7,82.5 225.7,234.8 187.1,234.8 187.1,82.5 155.3,82.5 106.2,169.6 106.2,234.8
	67.4,234.8 67.4,169.6 0.2,50.3 43.8,50.3 85.9,129.8 87.7,129.8 129.8,50.3 "
                />
              </clipPath>
            </svg>
          </FaqClip>
        </Box>
        <Box style={{ flex: 3 }}>
          {questions.map((question, i) => (
            <FaqDropdown key={i} title={question.title} text={question.text}/>
          ))}
        </Box>
      </Flex>
    </Container>
  )
}

export default Faq;
