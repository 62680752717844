import React from 'react';
import uuid from 'uuid';

import { RadioItem, RadioItemPrice, RadioItemText } from "../styles/Form";
import { Text, TextS } from "../styles/Texts";
import { numberToDevice } from "../subscription/utils";
import { useTranslation } from "react-i18next";


export default function Plans({ onChange, isMobile, ...props }) {
  const { t } = useTranslation();
  const optionRealisation = [
    {
      id: 'silver',
      name: 'option-realisation',
      title: 'Silver',
      subtitle: t('option1'),
      content: (
        <ul className="tick-list">
          <li>
            <TextS>{t('option4')}</TextS>
          </li>
          <li>
            <TextS>{t('option5')}</TextS>
          </li>
          <li>
            <TextS>{t('option6')}</TextS>
          </li>
          <li>
            <TextS>Décors non inclus</TextS>
          </li>
        </ul>
      ),
      price: 450000,
    },
    {
      id: 'gold',
      name: 'option-realisation',
      title: 'Gold',
      subtitle: t('option2'),
      content: (
        <ul className="tick-list">
          <li>
            <TextS>{t('option4')}</TextS>
          </li>
          <li>
            <TextS>{t('option5')}</TextS>
          </li>
          <li>
            <TextS>
              {t('option7')}
            </TextS>
          </li>
          <li>
            <TextS>{t('option9')}</TextS>
          </li>
        </ul>
      ),
      price: 900000,
    },
    {
      id: 'platinium',
      name: 'option-realisation',
      title: 'Platinium',
      subtitle: t('option3'),
      content: (
        <ul className="tick-list">
          <li>
            <TextS>{t('option4')}</TextS>
          </li>
          <li>
            <TextS>{t('option5')}</TextS>
          </li>
          <li>
            <TextS>
              {t('option8')}
            </TextS>
          </li>
          <li>
            <TextS>{t('option10')}</TextS>
          </li>
        </ul>
      ),
      price: 1350000,
    },
  ];

  function handleRadios(id, field) {
    if (!props[field] || props[field] !== id) {
      const e = {
        target: {
          type: 'radio',
          id
        }
      };
      onChange(e, field);
    }
  }

  return (
    <>
      {optionRealisation.map(({ id, name, title, subtitle, content }) => {
        const checked = props[name] && props[name] === id;
        let price = 0;
        if (title === 'Silver') {
          price = props['selectedCountry'].countryServices.find(
            cs => cs.service.serviceType === 'PACK_SILVER'
          ).amount;
        } else if (title === 'Gold') {
          price = props['selectedCountry'].countryServices.find(
            cs => cs.service.serviceType === 'PACK_GOLD'
          ).amount;
        } else {
          price = props['selectedCountry'].countryServices.find(
            cs => cs.service.serviceType === 'PACK_PLATINIUM'
          ).amount;
        }
        return (
          <RadioItem
            key={uuid()}
            onClick={() => handleRadios(id, name)}
            style={{ display: 'block' }}
          >
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <RadioItemText>
                <Text>{title}</Text>
              </RadioItemText>
              <RadioItemPrice>
                <input
                  type="radio"
                  id={id}
                  name={name}
                  onChange={() => handleRadios(id, name)}
                  checked={checked}
                />
                <label htmlFor={id}>
                  <TextS>
                    {numberToDevice(price)} {props['selectedCountry'].currency}
                  </TextS>
                  <span></span>
                </label>
              </RadioItemPrice>
            </span>
            <RadioItemText>
              <Text orange py={1}>
                {subtitle}
              </Text>
              {content}
            </RadioItemText>
          </RadioItem>
        );
      })}
    </>
  );
}
