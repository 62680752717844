import React from 'react';

import { ServicesWrapper, HoverOnly, ServicesItem } from './other-services.style';
import { Title5 } from '../styles/Titles';
import Button from '../styles/Button';

function OtherServices({ itemsList }) {
  return (
    <ServicesWrapper>
      {itemsList.map((el, i) => (
        <ServicesItem key={i}>
          <img src={el.img} alt="service" />
          <HoverOnly>
            <Title5>{el.title}</Title5>
            <Button href={el.link}>{el.cta}</Button>
          </HoverOnly>
        </ServicesItem>
      ))}
    </ServicesWrapper>
  );
}

export default OtherServices;
