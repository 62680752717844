import React from 'react'

import { Box } from '../Components/styles/Container'
import { Title4 } from '../Components/styles/Titles'
import Button from '../Components/styles/Button'
import { Text } from '../Components/styles/Texts'
import Banner from '../Components/Banner'
import imgCover from '../img/confirmation.jpg'
import { AvantageSlider } from '../Components/Avantages'
import { useTranslation } from "react-i18next";

export default function ConfirmationRender({ user }) {
  const { t } = useTranslation();
  return (
    <>
      <Banner
        children={
          <>
            <Box pt={5} center>
              <Title4 bold>
                {t('hello')}{' '}
                <span>
										{user ? user.firstName : ''}
									</span>
                ,
              </Title4>
            </Box>
            <Box center>
              <Text>
                {t('welcome1')}
                <br/> {t('welcome2')}
                <br/> {t('welcome3')}
              </Text>
              <Button mt={4} href="/services">
                {t('seeServices')}
              </Button>
            </Box>
          </>
        }
        img={imgCover}
        alt="Confirmation"
        imgHeight="500px"
        cta
      />
      <AvantageSlider/>
    </>
  )
}
