import React from 'react';

import Container, { Box } from '../styles/Container';
import { Form, FormCol } from '../styles/Form';
import Button from '../styles/Button';
import Link from '../styles/Link';
import CountryDropdown from '../form/CountryDropdown';
import PasswordInput from '../form/Password';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Text, TextS } from '../styles/Texts';
import InputText from '../form/InputText';
import { useTranslation } from 'react-i18next';
import { Title2 } from '../styles/Titles';

export default function Whoami({ validated, handleSubmit, onChange, register, ...props }) {
  const { t } = useTranslation();
  const SignUpSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('firstNameLeast'))
      .max(20, t('firstNameMax'))
      .required(t('firstNameEnter'))
      .matches(/^[a-zA-Z]/, { message: t('firstNameCheck'), excludeEmptyString: true }),
    lastName: Yup.string()
      .min(2, t('surnameLeast'))
      .max(20, t('surnameMax'))
      .required(t('surnameEnter'))
      .matches(/^[a-zA-Z]/, { message: t('surnameCheck'), excludeEmptyString: true }),
    tel: Yup.string()
      .min(6, t('phoneLeast'))
      .max(15, t('phoneMax'))
      .required(t('phoneEnter'))
      .matches(/^[+]/, {
        message: t('phoneCheck'),
        excludeEmptyString: true
      })
      .matches(
        /^[+]((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        {
          message: t('phoneInvalid'),
          excludeEmptyString: true
        }
      ),
    emailAddress: Yup.string()
      .email(t('emailInvalid'))
      .required(t('emailEnter')),
    password: Yup.string()
      .min(8, t('passwordLeast'))
      .required(t('enterPassword'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t('passwordIncorrect')),
    country: Yup.string().test('country', t('countryEnter'), () => props['country'] != null)
  });
  return (
    <>
      <Container small>
        <Box pb={4}>
          <Title2>
            <span>{t('registration.create')}</span> {t('registration.account')}
          </Title2>
        </Box>
      </Container>
      <Container small>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            tel: '',
            emailAddress: ''
          }}
          validationSchema={SignUpSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => {
            register();
          }}
        >
          {({ values, errors, handleChange, handleSubmit }) => (
            <Form white>
              <FormCol>
                <InputText
                  name="lastName"
                  label={t('registration.surname') + '* :'}
                  value={props['lastName'] || ''}
                  handleChange={e => {
                    onChange(e, 'lastName');
                    handleChange(e);
                  }}
                />
                {errors.lastName && <Text error>{errors.lastName}</Text>}
              </FormCol>

              <FormCol>
                <InputText
                  name="firstName"
                  label={t('registration.firstName') + '* :'}
                  value={props['firstName'] || ''}
                  handleChange={e => {
                    onChange(e, 'firstName');
                    handleChange(e);
                  }}
                />
                {errors.firstName && <Text error>{errors.firstName}</Text>}
              </FormCol>
              <FormCol>
                <InputText
                  name="emailAddress"
                  label={t('registration.email') + '* :'}
                  value={props['emailAddress'] || ''}
                  handleChange={e => {
                    onChange(e, 'emailAddress');
                    handleChange(e);
                  }}
                />
                {errors.emailAddress && <Text error>{errors.emailAddress}</Text>}
                {props['errors'] && <Text error>{props['errors']}</Text>}
              </FormCol>
              <FormCol>
                <CountryDropdown
                  label={t('registration.country') + '* :'}
                  value={''}
                  onCountryChange={props.onCountryChange}
                  onSuggestions={props.onSuggestions}
                  countries={props['countries']}
                />
                {errors.country && <Text error>{errors.country}</Text>}
              </FormCol>
              <FormCol>
                <InputText
                  name="tel"
                  label={t('registration.phone') + '* :'}
                  value={props['tel'] || ''}
                  handleChange={e => {
                    onChange(e, 'tel');
                    handleChange(e);
                  }}
                />
                {errors.tel && <Text error>{errors.tel}</Text>}
              </FormCol>
              <FormCol>
                <PasswordInput
                  name="password"
                  onChange={onChange}
                  values={values}
                  label={t('registration.password') + '* :'}
                  handleChange={handleChange}
                />
                {errors.password && <Text error>{errors.password}</Text>}
              </FormCol>
              <FormCol dSNone />
              <Box center>
                <Box mb={4}>
                  <TextS>*{t('registration.required')}</TextS>
                </Box>
                <Button fullWidth onClick={handleSubmit}>
                  {t('registration.subscribe')}
                </Button>
                <Box mt={3} center>
                  <Link orange href="/connexion">
                    {t('registration.already')}
                  </Link>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
