import styled, { css } from 'styled-components';
import { Box } from '../styles/Container';

const StyledFilter = styled(Box)`
  border-top: 1px solid rgb(83, 83, 83);
  border-bottom: 1px solid rgb(83, 83, 83);
  overflow: hidden;

  .filters {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s cubic-bezier(0, 1, 0, 1);

    &.show {
      max-height: 1000px;
      transition: max-height 0.4s ease-in-out;
    }
  }
`;

const OrderState = styled.div`
	width: 20px;
	height: 20px;
	position: absolute;
	right: 20px;
	border-radius: 20px;
	transition: all 1s;
	
    &.open {
        margin: auto auto 1rem auto;
        position: relative;
        right: 0;
	}

	&::after {
		white-space: nowrap;
		opacity: 0;
		transition: all 0.2s;
	}

	&:hover::after,
	&.open::after {
		opacity: 1;
		transition: all 1s;
	}

	${props =>
    props.status === 'red' &&
    css`
      background: ${props => props.theme.colors.red};
      &::after {
        content: 'En attente de paiement';
      }
      &:hover,
      &.open {
        width: 200px;
        transition: all 0.2s;
      }
    `}
    ${props =>
      props.status === 'grey' &&
      css`
        background: ${props => props.theme.colors.grey};
        &::after {
          content: 'Annulée';
        }
        &:hover,
        &.open {
          width: 195px;
          transition: all 0.2s;
        }
      `}
    ${props =>
      props.status === 'orange' &&
      css`
        background: ${props => props.theme.colors.orange};
        &::after {
          content: 'En cours de réalisation';
        }
        &:hover,
        &.open {
          width: 195px;
          transition: all 0.2s;
        }
      `}
    ${props =>
      props.status === 'green' &&
      css`
        background: ${props => props.theme.colors.green};
        &::after {
          content: 'Commande payée et livrée';
        }
        &:hover,
        &.open {
          width: 225px;
          transition: all 0.2s;
        }
      `}
`;

export { StyledFilter, OrderState };
