import trailerVideo from '../../img/distribution.mp4';
import imgDiffuser from '../../img/diffusion-youtube.png';
import imgPromo from '../../img/trace-social.png';
import imgRealiser from '../../img/maitre-gims.png';
import imgDistribution from '../../img/distribution.png';

const fr = {
  video: trailerVideo,
  title: 'Distribuer ma musique',
  subtitle: '<span>Partage ta musique</span> sur les <br/> plateformes de streaming',
  callToAction: 'Commencer',
  introduction: {
    img: imgDistribution,
    title: 'Vendre ma musique <span>en ligne</span>',
    paragraph:
      "La distribution de ta musique sur les plateformes de streaming est offerte si tu souscris à l'un des services YouTrace lors de ta première commande.<br/><br/>Après ta première commande, tu peux distribuer toute ta musique sur plus de 200 plateformes de streaming dans le monde."
  },
  services: {
    title: 'Les autres services YouTrace',
    items: [
      {
        title: 'Réaliser mon clip',
        cta: 'Découvrir',
        link: '/services/realisation',
        img: imgRealiser
      },
      {
        title: 'Diffuser mon clip',
        cta: 'Découvrir',
        link: '/services/diffusion',
        img: imgDiffuser
      },
      {
        title: 'Faire ma promo digitale',
        cta: 'Découvrir',
        link: '/services/promotion',
        img: imgPromo
      }
    ]
  }
};
const en = {
  video: trailerVideo,
  title: 'Distribute your music',
  subtitle: '<span>Sell your music</span> on the music streaming platforms of your choice,<br/>including Spotify, iTunes, Deezer and more',
  callToAction: 'Get started',
  introduction: {
    img: imgDistribution,
    title: 'Sell your music <span>online</span>',
    paragraph:
      'In order to sell your music on music streaming services, you first have to sign up for one of our packages.<br/><br/>After your first order (of one of our packages), you can distribute your music on more than 200 streaming platforms around the world.'
  },
  services: {
    title: 'Other YouTrace services',
    items: [
      {
        title: 'Produce my Video',
        cta: 'Discover',
        link: '/services/realisation',
        img: imgRealiser
      },
      {
        title: 'Feature my Video',
        cta: 'Discover',
        link: '/services/diffusion',
        img: imgDiffuser
      },
      {
        title: 'Create a Campaign for Me',
        cta: 'Discover',
        link: '/services/promotion',
        img: imgPromo
      }
    ]
  }
};
const pt = {
  video: trailerVideo,
  title: 'Compartilhe sua música',
  subtitle:
    '<span>Compartilhe sua música</span> nas <br/>  plataformas de streaming de sua escolha',
  callToAction: 'Começar',
  introduction: {
    img: imgDistribution,
    title: 'Venda sua música <span>online</span>',
    paragraph:
      'Você assina um dos serviços YouTrace em seu primeiro pedido.<br/><br/>Após seu primeiro pedido, você pode distribuir todas as suas músicas em mais de 200 plataformas de streaming em todo o mundo.'
  },
  services: {
    title: 'Outros Serviços da YouTrace',
    items: [
      {
        title: 'Crie meu vídeo',
        cta: 'Descobrir',
        link: '/services/realisation',
        img: imgRealiser
      },
      {
        title: 'Transmitir meu vídeo',
        cta: 'Descobrir',
        link: '/services/diffusion',
        img: imgDiffuser
      },
      {
        title: 'Faça minha promoção digital',
        cta: 'Descobrir',
        link: '/services/promotion',
        img: imgPromo
      }
    ]
  }
};

export { fr, en, pt };
