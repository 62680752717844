import React from 'react';

import Container, { Box } from '../styles/Container';
import { Title4 } from '../styles/Titles';
import { Form, FormCol } from '../styles/Form';

import Button from '../styles/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Text } from '../styles/Texts';
import InputText from '../form/InputText';
import { useTranslation } from 'react-i18next';

export default function ResetPasswordRender({
  onChange,
  handleReset,
  changeStatus,
  resetPasswordHandler,
  success
}) {
  const { t } = useTranslation();
  const ResetSchema = Yup.object().shape({
    login: Yup.string()
      .email(t('checkEmail'))
      .min(2, t('emailShort'))
      .max(40, t('emailLong'))
      .required(t('completeField'))
  });
  return (
    <>
      <Container>
        <Box p={4} center>
          <Title4 bold>{t('forgottenPassword')} ?</Title4>
        </Box>
      </Container>
      <Container XS>
        <Formik
          initialValues={{
            login: ''
          }}
          validationSchema={ResetSchema}
          onSubmit={values => {
            handleReset();
            changeStatus();
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <Form white>
              {success ? (
                <Box pb={4} center>
                  <Text pb={2} orange>
                    {t('resetPassword')}.
                  </Text>
                </Box>
              ) : (
                <>
                  <Text>{t('enterEmail')}</Text>
                  <FormCol my={2} mx="auto" fWidth>
                    <InputText
                      label="E-mail* :"
                      name="login"
                      onBlur={handleBlur}
                      handleChange={e => {
                        onChange(e, 'login');
                        handleChange(e);
                      }}
                      value={values.login}
                    />
                    {errors.login && <Text error>{errors.login}</Text>}
                    <Button mt={[2, 2, 2, 4]} onClick={handleSubmit}>
                      {t('reset')}
                    </Button>
                    <Box mt={4} center>
                      <Text orange onClick={() => resetPasswordHandler()} pointer>
                        {t('login')}
                      </Text>
                    </Box>
                  </FormCol>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
