import React from 'react';

import { Bloc, BlocCol, BlocCols } from '../styles/Bloc';
import { TextS, TextXXS } from '../styles/Texts';
import Container, { Box } from '../styles/Container';
import { useTranslation } from 'react-i18next';

export default function AccountInfo({ modifying, user, toggleModifying }) {
  const { t } = useTranslation();
  return (
    <>
      <Container small mt={4}>
        <Bloc>
          <Box>
            <BlocCols>
              <BlocCol>
                <TextXXS className="opacity" mb={2}>
                  {t('registration.surname')}
                </TextXXS>
                <TextS>{user ? user.lastName : ''}</TextS>
              </BlocCol>
              <BlocCol>
                <TextXXS className="opacity" mb={2}>
                  {t('registration.firstName')}
                </TextXXS>
                <TextS>{user ? user.firstName : ''}</TextS>
              </BlocCol>
              <BlocCol>
                <TextXXS className="opacity" mb={2}>
                  {t('registration.phone')}
                </TextXXS>
                <TextS>{user ? user.phone : ''}</TextS>
              </BlocCol>
              <BlocCol>
                <TextXXS className="opacity" mb={2}>
                  {t('registration.email')}
                </TextXXS>
                <TextS>{user ? user.emailAddress : ''}</TextS>
              </BlocCol>
              <BlocCol>
                <TextXXS className="opacity" mb={2}>
                  {t('registration.country')}
                </TextXXS>
                <TextS>{user ? user.country.country : ''}</TextS>
              </BlocCol>
            </BlocCols>
          </Box>
          <Box center my={4}>
            <TextS orange onClick={toggleModifying} style={{ cursor: 'pointer' }}>
              {t('edit')}
            </TextS>
          </Box>
        </Bloc>
      </Container>
    </>
  );
}
