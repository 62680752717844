import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Loader from 'react-loader-spinner';

import Container, { Box } from '../Components/styles/Container';
import { Form, FormCol } from '../Components/styles/Form';
import Button from '../Components/styles/Button';
import { Title2 } from '../Components/styles/Titles';
import PasswordInput from '../Components/form/Password';
import { AvantageSlider } from '../Components/Avantages';
import { Text } from '../Components/styles/Texts';
import { BannerImg, BannerWrapper } from '../Components/Banner';
import bgImg from '../img/connexion.jpg';
import api from '../client/api';

export default function Password(props) {
  const [password, setPassword] = useState('');
  const [verified, setVerified] = useState(false);
  const [expired, setExpired] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  const SignUpSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('passwordLeast'))
      .required(t('enterPassword'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t('passwordIncorrect'))
  });

  useEffect(() => {
    api.checkToken(props.match.params.token, response => {
      console.log(response);
      if (response.reponse === 'OK') {
        setVerified(true);
      } else {
        setExpired(true);
      }
    });
  }, [props]);

  function reset() {
    api.updatePassword(
      {
        password: password,
        token: props.match.params.token
      },
      response => {
        setSuccess(true);
      }
    );
  }

  function onChangePassword(e) {
    if (e.target.value !== '') {
      setPassword(e.target.value);
    }
  }

  return (
    <>
      <BannerWrapper>
        <BannerImg src={bgImg} alt="YouTrace Inscription" className="index" />

        <Box py={[2, 3]}>
          <Container>
            <Box pt={4} center>
              <Title2 bold>{t('resetYourPassword')}</Title2>
            </Box>
          </Container>
          <Container XS>
            {success ? (
              <>
                <Text center pb={2} mb={2}>
                  {t('passwordSaved')}.
                </Text>
                <Button m="auto" mt={2} href="/connexion">
                  {t('login')}
                </Button>
              </>
            ) : expired ? (
              <Text center pb={2}>
                {t('expired')}.
              </Text>
            ) : verified ? (
              <Formik
                initialValues={{
                  password: ''
                }}
                validationSchema={SignUpSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={values => {
                  reset();
                }}
              >
                {({ values, errors, handleSubmit, handleChange }) => (
                  <Form white>
                    <FormCol my={2} mx="auto" fWidth>
                      <PasswordInput
                        name="password"
                        label={t('newPassword') + ' :'}
                        onChange={onChangePassword}
                        handleChange={handleChange}
                      />
                      {errors.password && <Text error>{errors.password}</Text>}
                    </FormCol>
                    <Box center pb={4}>
                      <Button onClick={handleSubmit}>{t('update')}</Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            ) : (
              <Loader
                type="TailSpin"
                color="#fff"
                height={25}
                width={25}
                style={{ textAlign: 'center', margin: '5rem' }}
              />
            )}
          </Container>
        </Box>
      </BannerWrapper>
      <AvantageSlider />
    </>
  );
}
