import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { useTranslation } from 'react-i18next';

import Container, { Box, Flex } from '../styles/Container';
import { Checkbox, Input, RadioItemPrice } from '../styles/Form';
import { Text, TextS, TextXS, TextXXS } from '../styles/Texts';
import Button from '../styles/Button';
import { Bloc, BlocCheckboxes, BlocCol, BlocCol1, BlocCols, BlocRadio } from '../styles/Bloc';
import Link from '../styles/Link';
import { Title4 } from '../styles/Titles';
import paiementWari from '../../img/wari.png';
import touchpay from '../../img/touchpay.png';
import logoYouTrace from '../../img/logo-bg.png';
import { numberToDevice } from '../subscription/utils';
import paypal from '../../img/paypal.svg';
import ScrollToTop from '../ScrollToTop';

export default function Recap({
  goToInformations,
  getPackPrice,
  getPromoPrice,
  getDiffusionPrice,
  goToServices,
  onChange,
  isMobile,
  turnOffDiffusion,
  handleCCProd,
  handleCDist,
  handleCgv,
  ...props
}) {
  function handleRadios(id, field) {
    setDisableTouchpay(false);
    if (!props[field] || props[field] !== id) {
      const e = {
        target: {
          type: 'radio',
          id
        }
      };
      onChange(e, field);
    }
  }

  function handleCheckboxes(checked, field) {
    const e = {
      target: {
        type: 'checkbox',
        checked
      }
    };
    onChange(e, field);
  }

  function insertScript() {
    const script = document.createElement('script');
    script.src = `https://touchpay.gutouch.com/touchpay/script/prod_touchpay-0.0.1.js`;
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }

  const [disableTouchpay, setDisableTouchpay] = useState(false);

  async function calltouchpay(total, city, email, firstName, lastName, phone) {
    setDisableTouchpay(true);
    let order = await props.pay(true);
    window.sendPaymentInfos(
      order.identity,
      props.user.country.agency,
      props.user.country.token,
      'trace.tv',
      window.location.origin + '/touchpay/success',
      window.location.origin + '/touchpay/failed',
      total,
      city,
      email,
      firstName,
      lastName,
      ''
    );
  }

  insertScript();

  const packPrice = getPackPrice();
  const promoPrice = getPromoPrice();
  const diffusionPrice = getDiffusionPrice();
  const total = numberToDevice(Number(packPrice) + Number(promoPrice) + Number(diffusionPrice));
  const totalOrigin = Number(packPrice) + Number(promoPrice) + Number(diffusionPrice);
  const paypalCurrencies = ['USD', 'CAD'];
  const currentCurrency = props.selectedCountry.currency;
  const currency = paypalCurrencies.includes(currentCurrency) ? currentCurrency : 'EUR';
  const paypalTotal = totalOrigin * parseFloat(props.selectedCountry.euroRate);
  const onSuccess = payment => {
    props.pay(payment);
  };

  const onCancel = data => {
    //console.log('The payment was cancelled!', data)
  };

  const onError = err => {
    //console.log('Error!', err)
  };
  let env = props.env;
  const client = {
    sandbox: 'AZJh46YVuv27iExNHHsUdaklCVw_cArFBSlo83FMVnmMMkZAPGo0gutkKXOMmV5WKT0M3uOchRO7N_lc',
    production: 'AbsUCzukK8SE8CyeYGSffqo98Fzw3rMk0biu33HVJw3oit_5CAQQ8tjutwuhQzb4l7C0gQxImgFRvQli'
  };
  const stylePaypal = {
    size: 'responsive',
    color: 'gold',
    shape: 'rect'
  };
  const date = props['diffusionDate'];
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const { t, i18n } = useTranslation();
  let locale = i18n.language.slice(0, 2);
  const optionRealisation = [
    {
      id: 'silver',
      name: 'option-realisation',
      title: 'Silver',
      subtitle: t('option1'),
      content: (
        <ul className="tick-list">
          <li>
            <TextS>{t('option4')}</TextS>
          </li>
          <li>
            <TextS>{t('option5')}</TextS>
          </li>
          <li>
            <TextS>{t('option6')}</TextS>
          </li>
          <li>
            <TextS>{t('option11')}</TextS>
          </li>
        </ul>
      ),
      price: 450000
    },
    {
      id: 'gold',
      name: 'option-realisation',
      title: 'Gold',
      subtitle: t('option2'),
      content: (
        <ul className="tick-list">
          <li>
            <TextS>{t('option4')}</TextS>
          </li>
          <li>
            <TextS>{t('option5')}</TextS>
          </li>
          <li>
            <TextS>{t('option7')}</TextS>
          </li>
          <li>
            <TextS>{t('option9')}</TextS>
          </li>
        </ul>
      ),
      price: 900000
    },
    {
      id: 'platinium',
      name: 'option-realisation',
      title: 'Platinium',
      subtitle: t('option3'),
      content: (
        <ul className="tick-list">
          <li>
            <TextS>{t('option4')}</TextS>
          </li>
          <li>
            <TextS>{t('option5')}</TextS>
          </li>
          <li>
            <TextS>{t('option8')}</TextS>
          </li>
          <li>
            <TextS>{t('option10')}</TextS>
          </li>
        </ul>
      ),
      price: 1350000
    }
  ];
  return (
    <Box pb={5}>
      <ScrollToTop />
      <Container small>
        <Box center my={5}>
          <Title4>{t('orderSummary')}</Title4>
        </Box>
        <Bloc>
          <Box>
            {props['titleName'] && (
              <>
                <TextXXS orange bold py={3}>
                  {t('videoInformation')}
                </TextXXS>
                <BlocCols>
                  <BlocCol>
                    <TextXXS className="opacity" mb={2}>
                      {t('title')}
                    </TextXXS>
                    <Text>{props['titleName']}</Text>
                  </BlocCol>

                  {props['optionRealisation'] && (
                    <>
                      <BlocCol>
                        <TextXXS className="opacity" mb={2}>
                          {t('registration.country')}
                        </TextXXS>
                        <Text>{props['selectedCountry'].country}</Text>
                      </BlocCol>
                      {props['producer'] && (
                        <BlocCol>
                          <TextXXS className="opacity" mb={2}>
                            {t('producer')}
                          </TextXXS>
                          <Text>{props['producer']}</Text>
                        </BlocCol>
                      )}
                    </>
                  )}

                  {props['diffusionDate'] && (
                    <BlocCol>
                      <TextXXS className="opacity" mb={2}>
                        {t('broadcastDate')}
                      </TextXXS>
                      <Text>
                        {da}/{mo}/{ye}
                      </Text>
                    </BlocCol>
                  )}
                </BlocCols>
              </>
            )}
            <TextXXS mt={3} orange bold py={3}>
              {t('offerAndOption')}
            </TextXXS>
            <BlocCols>
              <BlocCol1>
                <Flex justifyContent="space-between">
                  <Box>
                    <TextS>
                      {props['optionRealisation']
                        ? props['option-realisation']
                        : 'Réaliser mon clip'}
                    </TextS>
                    {props['optionRealisation'] && (
                      <TextXS className="opacity">
                        {optionRealisation.find(o => o.id === props['option-realisation']).subtitle}
                      </TextXS>
                    )}
                  </Box>
                  <TextS style={{ textAlign: 'right', whiteSpace: 'nowrap' }} orange>
                    {props['optionRealisation']
                      ? numberToDevice(packPrice) + ' ' + props['selectedCountry'].currency
                      : t('no')}
                  </TextS>
                </Flex>
              </BlocCol1>
              <BlocCol1>
                <Flex justifyContent="space-between">
                  <TextS>{t('broadcastYourVideo')}</TextS>
                  <TextS
                    orange
                    style={{ textAlign: 'right', cursor: 'pointer' }}
                    onClick={props['optionRealisation'] ? turnOffDiffusion : undefined}
                  >
                    {!props['optionRealisation'] &&
                      props['optionDiffusion'] &&
                      numberToDevice(diffusionPrice) + ' ' + props['selectedCountry'].currency}
                    {props['optionRealisation'] && !props['optionDiffusion'] && (
                      <Button small>{t('activateOption')}</Button>
                    )}
                    {props['optionRealisation'] && props['optionDiffusion'] && (
                      <Button small>{t('disableOption')}</Button>
                    )}
                    {!props['optionRealisation'] && !props['optionDiffusion'] && t('no')}
                  </TextS>
                </Flex>
              </BlocCol1>
              <BlocCol1>
                <Flex justifyContent="space-between">
                  <TextS>{t('distribution.title')}</TextS>
                  <TextS orange style={{ textAlign: 'right' }}>
                    {props['optionDistribution'] === true ? t('yes') : t('no')}
                  </TextS>
                </Flex>
              </BlocCol1>
              <BlocCol1>
                <Flex justifyContent="space-between">
                  <TextS>{t('promoteYourVideo')}</TextS>
                  <TextS orange style={{ textAlign: 'right' }}>
                    {promoPrice === 0
                      ? t('no')
                      : numberToDevice(promoPrice) + ' ' + props['selectedCountry'].currency}
                  </TextS>
                </Flex>
              </BlocCol1>
              <Box center>
                <Link green onClick={goToServices}>
                  {t('editOrder')}
                </Link>
              </Box>
            </BlocCols>
            <BlocCols py={4} m={0} style={{ borderBottom: '0' }}>
              <BlocCol style={{ margin: 0 }}>
                <Text bold>{t('total')}</Text>
              </BlocCol>
              <BlocCol style={{ margin: 0 }}>
                <Text bold style={{ textAlign: 'right' }}>
                  {total} {props['selectedCountry'].currency}
                </Text>
              </BlocCol>
            </BlocCols>
          </Box>
        </Bloc>
        <Bloc>
          <TextXXS mt={3} orange bold py={2}>
            {t('validateMyContrat')}
          </TextXXS>
          <BlocCols pt={4} pb={2} m={0} style={{ borderBottom: '0' }}>
            <BlocCheckboxes>
              {props['optionRealisation'] && (
                <Checkbox>
                  <input
                    type="checkbox"
                    name="accept_monetisation"
                    onChange={() => null}
                    checked={props['accept_monetisation'] || false}
                  />
                  <label
                    onClick={() =>
                      handleCheckboxes(
                        !(props['accept_monetisation'] || false),
                        'accept_monetisation'
                      )
                    }
                    htmlFor="accept_monetisation"
                  >
                    <span></span>
                    <TextXS>{t('acceptCP')}</TextXS>
                  </label>
                  <TextXS
                    mr="auto"
                    my={2}
                    style={{ textAlign: 'right' }}
                    onClick={() => handleCCProd(locale)}
                  >
                    <Link orange>{t('seeCP')}</Link>
                  </TextXS>
                </Checkbox>
              )}
              {props['optionDistribution'] && (
                <Checkbox>
                  <input
                    type="checkbox"
                    name="accept_distribution"
                    onChange={() => null}
                    checked={props['accept_distribution'] || false}
                  />
                  <label
                    onClick={() =>
                      handleCheckboxes(
                        !(props['accept_distribution'] || false),
                        'accept_distribution'
                      )
                    }
                    htmlFor="accept_distribution"
                  >
                    <span></span>
                    <TextXS>{t('acceptDist')}</TextXS>
                  </label>
                  <TextXS
                    mr="auto"
                    my={2}
                    style={{ textAlign: 'right' }}
                    onClick={() => handleCDist(locale)}
                  >
                    <Link orange>{t('seeDist')}</Link>
                  </TextXS>
                </Checkbox>
              )}
              <Checkbox>
                <input
                  type="checkbox"
                  name="accept_tc"
                  onChange={() => null}
                  checked={props['accept_tc'] || false}
                />
                <label
                  htmlFor="accept_tc"
                  onClick={() => handleCheckboxes(!(props['accept_tc'] || false), 'accept_tc')}
                >
                  <span></span>
                  <TextXS>{t('acceptTC')}</TextXS>
                </label>
                <TextXS
                  mr="auto"
                  mt={2}
                  style={{ textAlign: 'right' }}
                  onClick={() => handleCgv(locale)}
                >
                  <Link orange>{t('seeTC')}</Link>
                </TextXS>
              </Checkbox>
            </BlocCheckboxes>
          </BlocCols>
        </Bloc>
        <Bloc pb={3} style={{ overflow: 'hidden' }}>
          <TextXXS orange bold mb={3} py={3}>
            {t('selectPayment')}
          </TextXXS>
          <BlocRadio>
            {props.selectedCountry.touchPayAvailable && (
              <RadioItemPrice>
                <Input
                  onChange={() => handleRadios('touchpay', 'packPrice-mode')}
                  checked={props['packPrice-mode'] && props['packPrice-mode'] === 'touchpay'}
                  type="radio"
                  id="touchpay"
                  name="price-mode"
                />
                <label htmlFor="touchpay">
                  <img alt="paiement" style={{ height: 33 }} src={touchpay} />
                  <span></span>
                </label>
              </RadioItemPrice>
            )}
            <RadioItemPrice>
              <Input
                onChange={() => handleRadios('paypal', 'packPrice-mode')}
                checked={props['packPrice-mode'] && props['packPrice-mode'] === 'paypal'}
                type="radio"
                id="paypal"
                name="price-mode"
              />
              <label htmlFor="paypal">
                <img alt="paiement" style={{ height: 33 }} src={paypal} />
                <span></span>
              </label>
            </RadioItemPrice>
            {props.selectedCountry.wariAvailable && (
              <RadioItemPrice>
                <Input
                  onChange={() => handleRadios('wari', 'packPrice-mode')}
                  checked={props['packPrice-mode'] && props['packPrice-mode'] === 'wari'}
                  type="radio"
                  id="wari"
                  name="price-mode"
                />
                <label htmlFor="wari">
                  <img alt="paiement" src={paiementWari} />
                  <span></span>
                </label>
              </RadioItemPrice>
            )}
            {props.selectedCountry.coordinatorAvailable && (
              <RadioItemPrice>
                <Input
                  onChange={() => handleRadios('coordinateur', 'packPrice-mode')}
                  checked={props['packPrice-mode'] && props['packPrice-mode'] === 'coordinateur'}
                  type="radio"
                  id="coordinateur"
                  name="price-mode"
                />
                <label htmlFor="coordinateur">
                  <img alt="paiement" src={logoYouTrace} />*<span></span>
                </label>
              </RadioItemPrice>
            )}
          </BlocRadio>
          {props.selectedCountry.coordinator && (
            <Box center>
              <TextXS mt={2}>*{t('payYoutraceRep')}</TextXS>
            </Box>
          )}
        </Bloc>
        <Box mt={3} center>
          {props['errors'] && <Text error>{props['errors']}</Text>}
          {props['packPrice-mode'] === 'coordinateur' && (
            <Button
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() => props.pay()}
            >
              {t('payYoutrace')}
            </Button>
          )}
          {props['packPrice-mode'] === 'wari' && (
            <Button
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() => props.pay()}
            >
              {t('payWari')}
            </Button>
          )}
          {!disableTouchpay && props['packPrice-mode'] === 'touchpay' && (
            <Button
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() =>
                props['accept_tc'] &&
                props['accept_distribution'] &&
                (props['accept_monetisation'] || !props['optionRealisation'])
                  ? calltouchpay(
                      totalOrigin,
                      '',
                      props.user.emailAddress,
                      props.user.firstName,
                      props.user.lastName,
                      props.user.phone
                    )
                  : props.pay(false)
              }
            >
              {t('touchpay')}
            </Button>
          )}
          {props['packPrice-mode'] === 'paypal' && (
            <Box style={{ position: 'relative' }}>
              {props['accept_distribution'] &&
                (props['accept_monetisation'] || !props['optionRealisation']) && (
                  <PaypalExpressBtn
                    style={stylePaypal}
                    client={client}
                    currency={currency}
                    total={paypalTotal}
                    env={env}
                    onError={onError}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                  />
                )}

              <Button
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={() => props.pay()}
              >
                <Loader
                  type="TailSpin"
                  color="#fff"
                  height={25}
                  width={25}
                  timeout={3000}
                  style={{ marginRight: 20 }}
                />
                {t('payPaypal')}
              </Button>
            </Box>
          )}
        </Box>
        <Box mt={3} center>
          <Link orange onClick={goToInformations}>
            <small>{t('previous')}</small>
          </Link>
        </Box>
      </Container>
    </Box>
  );
}
