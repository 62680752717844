import React, { Component } from 'react';

import { Input } from '../styles/Form';

class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      fieldActive: false
    };
    this.activateFocus = this.activateFocus.bind(this);
    this.disableFocus = this.disableFocus.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.inputValue) {
      return { fieldActive: true };
    } else return null;
  }

  componentDidMount(prevProps) {
    if (this.props.value !== '') {
      this.setState({
        fieldActive: true
      });
    }
  }

  activateFocus() {
    this.setState({
      fieldActive: true
    });
  }

  disableFocus(e) {
    if (e.target.value === '') {
      this.setState({
        fieldActive: false
      });
    }
  }

  updateInputValue(e) {
    this.setState({
      inputValue: e.target.value
    });
    this.activateFocus(e);
    e.preventDefault();
  }

  render() {
    return (
      <div style={{ textAlign: 'left' }}>
        <label className={this.state.fieldActive ? 'field-active' : 'field'}>
          {this.props.label}
        </label>
        <Input
          type="text"
          onFocus={this.activateFocus}
          onBlur={this.disableFocus}
          onChange={(this.updateInputValue, this.props.handleChange)}
          value={this.props.value}
          name={this.props.name}
          white={this.props.white}
        />
      </div>
    );
  }
}

export default InputText;
