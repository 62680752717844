import React, { Component } from 'react';
import api from '../client/api';
import { Box } from '../Components/styles/Container';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class Sso extends Component {
  componentDidMount() {
    api.sso();
  }

  render() {
    return <Box pb={5} />;
  }
}
