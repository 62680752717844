import React, { useState, Fragment } from 'react';

import Container, { Box } from '../Components/styles/Container';
import { BannerImg, BannerWrapper } from '../Components/Banner';
import LoginForm from '../Components/login/LoginForm';
import ResetPassword from '../Components/login/ResetPassword';
import { AvantageSlider } from '../Components/Avantages';

import bgImg from '../img/connexion.jpg';

export default function Login() {
  const [resetPassword, setResetPassword] = useState(false);

  function resetPasswordHandler() {
    setResetPassword(!resetPassword);
  }

  return (
    <Fragment>
      <BannerWrapper>
        <BannerImg src={bgImg} alt="YouTrace Inscription" className="index" />
        <Box py={[2, 3]} pb={4}>
          <Container small>
            {resetPassword ? (
              <ResetPassword resetPasswordHandler={resetPasswordHandler} />
            ) : (
              <LoginForm resetPasswordHandler={resetPasswordHandler} />
            )}
          </Container>
        </Box>
      </BannerWrapper>
      <AvantageSlider />
    </Fragment>
  );
}
