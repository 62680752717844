import styled from 'styled-components';
import { Box } from '../styles/Container';

export const StepsWrap = styled.div`
  position: relative;
  counter-reset: section;
  &::before {
    content: '';
    top: 10px;
    left: 88px;
    position: absolute;
    width: 10px;
    height: 100%;
    background: white;
    border: 3px solid ${props => props.theme.colors.orange};
  }
  &::after {
    content: '';
    bottom: -17px;
    left: 88px;
    position: absolute;
    width: 16px;
    height: 75px;
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 50%);
  }
  img {
    margin: 0 20px;
    width: 50px;
    heigth: auto;
  }
`;
export const StepsItem = styled(Box)`
  position: relative;
  display: flex;
  width: 100%;
  margin: 40px 0;
  &::before {
    counter-increment: section;
    content: counter(section);
    font-size: 70px;
    min-width: 70px;
    text-align: center;
    line-height: 1;
    line-height: 55px;
    margin-right: 40px;
  }
  &::after {
    content: '';
    border: 3px solid ${props => props.theme.colors.orange};
    background: white;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    left: 80px;
    top: 0;
  }
`;

export const StepsText = styled(Box)`
  h5,
  h4 {
    margin: 0 0 10px 0;
  }
`;
