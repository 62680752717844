import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import RegistrationForm from '../Components/registration/RegistrationForm';
import api from '../client/api';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: []
    };
  }

  componentDidMount() {
    api.getCountries(countries => {
      this.setState({ countries: countries });
    });
  }

  render() {
    // if user already "authenticated", redirect them to the app
    if (localStorage.getItem('access_token')) {
      return <Redirect to={'/services'} />;
    } else {
      return <RegistrationForm {...this.state} />;
    }
  }
}

export default Registration;
