import React from 'react';

import Container, { Box } from '../styles/Container';
import Button from '../styles/Button';
import Realisation from '../services/Realisation';
import Promotion from '../services/Promotion';
import Diffusion from '../services/Diffusion';
import { Title2 } from '../styles/Titles';
import { AvantageSlider } from '../Avantages';
import { Text } from '../styles/Texts';
import ScrollToTop from '../ScrollToTop';
import { useTranslation } from 'react-i18next';
import Distribution from '../services/Distribution';
import { userContext } from '../../context/userContext';

export default function Services({
  isMobile,
  onChange,
  onChangeCountry,
  onSuggestions,
  goToInformations,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <userContext.Consumer>
      {({ user }) => {
        return (
          <>
            <ScrollToTop />
            <Container small>
              <Box center my={5}>
                <Title2>
                  {t('services.title.part1')} <span>{t('services.title.part2')}</span>
                </Title2>
              </Box>
              <Realisation onChangeCountry={onChangeCountry} onChange={onChange} {...props} />
              <Diffusion onChange={onChange} {...props} />
              <Promotion onChange={onChange} {...props} />
              {user && user.optionDistribution === false && (
                <Distribution onChange={onChange} {...props} />
              )}
              <Box center>
                {props['errors'] && (
                  <Text error center>
                    {props['errors']}
                  </Text>
                )}
                <Button fullWidth onClick={goToInformations}>
                  {t('services.enter')}
                </Button>
              </Box>
              <Box showMedium pb={3} />
            </Container>
            <AvantageSlider />
          </>
        );
      }}
    </userContext.Consumer>
  );
}
