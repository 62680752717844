import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../styles/Button';
import Container, { Box } from '../styles/Container';
import Modal, { ModalClose, ModalWrapper } from '../styles/Modal';
import { Title3 } from '../styles/Titles';

export default function Comment({ close, note, setNote, save }) {
  const { t } = useTranslation();
  return (
    <Modal>
      <Container small>
        <ModalWrapper>
          <ModalClose onClick={close}>×</ModalClose>
          <Box mb={4} center>
            <Title3 bold>{t('notes')}:</Title3>
          </Box>
          <TextArea value={note} onChange={e => setNote(e.target.value)} />
          <Button fullWidth onClick={save}>
            {t('save')}
          </Button>
        </ModalWrapper>
      </Container>
    </Modal>
  );
}

const TextArea = styled.textarea`
  background: transparent;
  width: 100%;
  border: 1px solid #fff;
  color: #fff;
  min-height: 300px;
  padding: 10px;
  &:focus {
    outline: none;
  }
`;
