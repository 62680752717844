import React from 'react';
import styled, { css } from 'styled-components';

import Container from './styles/Container';

const BannerWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  text-align: center;
  min-height: 170px;
  flex-direction: column;
  background: #000;
  color: #fff;
  ${props =>
  props.center &&
  css`
      align-items: center;
      justify-content: center;
    `}
`;

const BannerImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
  z-index: 0;
  left: 0;
`;

function Banner({ img, alt, imgHeight, children }) {
  return (
    <BannerWrapper center style={{ minHeight: imgHeight }}>
      <BannerImg src={img} alt={alt}/>
      <Container>{children}</Container>
    </BannerWrapper>
  );
}

export default Banner;
export { BannerWrapper, BannerImg };
